import React from "react";
import { Link } from "react-router-dom";
import messages from "utils/messages";
import * as storage from "utils/storage";
import config from "config";
import { isAndroid, isIOS } from "react-device-detect";

export default class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
    };
  }
  componentWillMount() {
    const user = storage.get("edc_user");
    if (user) this.setState({ loggedIn: true });
  }
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const promo = urlParams.get("promo");
    const ref = urlParams.get("ref");
    const source = ref || document.referrer;
    const branchLink = `${config.branchLink}?promo=${promo}&source=${source}`;
    return (
      <div className="nav container">
        <div className="nav-wrapper">
          {this.state.loggedIn ? (
            <ul className="nav-options">
              <li>
                <a
                  href={config.externalAppUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.GO_TO_APP}
                </a>
              </li>
            </ul>
          ) : (
            <ul className="nav-options">
              <li>
                <Link to={"https://app.everyday.app/signup"}>
                  {messages.SIGNUP}
                </Link>
              </li>
              <li>
                <Link to={"https://app.everyday.app/login"}>
                  {messages.LOGIN}
                </Link>
              </li>
              <li>
                <a
                  className="iphone"
                  href={
                    isIOS
                      ? branchLink
                      : "https://itunes.apple.com/us/app/everyday-habit-tracker/id1394150432"
                  }
                >
                  <i className="ion-social-apple"></i>
                </a>
              </li>
              <li className="less-margin nohover">
                <a className="plus">+</a>
              </li>
              <li className="less-margin">
                <a
                  className="android"
                  href={
                    isAndroid
                      ? branchLink
                      : "https://play.google.com/store/apps/details?id=com.everydaycheckmobile"
                  }
                >
                  <i className="ion-social-android"></i>
                </a>
              </li>
            </ul>
          )}
          <Link to="/" className="brand-wrapper">
            <span className="logo"></span>
            <span className="brand">everyday</span>
          </Link>
        </div>
      </div>
    );
  }
}
