import React from "react";
import * as Components from "components";
import messages from "utils/messages";
import { Helmet } from "react-helmet";

export default class Terms extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="terms-page">
        <Helmet>
          <title>{messages.H_TERMS}</title>
          <meta name="description" content={messages.H_DESC_TERMS} />
          <link
            rel="alternate"
            href="https://everyday.app/terms"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.TERMS_AND_CONDITIONS}</h1>
            <h2>{messages.TAC_SUBTITLE}</h2>
            <div className="text">
              <p>{messages.TAC_01}</p>
              <p>{messages.TAC_02}</p>
              <p>{messages.TAC_03}</p>
              <h3>{messages.TAC_PRODUCT}</h3>
              <p>{messages.TAC_P_01}</p>
              <p>{messages.TAC_P_02}</p>
              <p>{messages.TAC_P_021}</p>
              <p>{messages.TAC_P_022}</p>
              <p>{messages.TAC_P_023}</p>
              <p>{messages.TAC_P_024}</p>
              <p>{messages.TAC_P_025}</p>
              <p>{messages.TAC_P_026}</p>
              <p>{messages.TAC_P_027}</p>
              <p>{messages.TAC_P_03}</p>
              <p>{messages.TAC_P_04}</p>
              <h3>{messages.TAC_EXCLUSION_OF_LIABILITY}</h3>
              <p>{messages.TAC_EOL_01}</p>
              <p>{messages.TAC_EOL_02}</p>
              <p>{messages.TAC_EOL_03}</p>
              <p>{messages.TAC_EOL_04}</p>
              <p>{messages.TAC_EOL_05}</p>
              <p>{messages.TAC_EOL_06}</p>
              <h3>{messages.TAC_ACCOUNTS}</h3>
              <p>{messages.TAC_04}</p>
              <p>{messages.TAC_05}</p>
              <p>{messages.TAC_06}</p>
              <h3>{messages.TAC_LINKS}</h3>
              <p>{messages.TAC_07}</p>
              <p>{messages.TAC_08}</p>
              <p>{messages.TAC_09}</p>
              <h3>{messages.TAC_TERMINATION}</h3>
              <p>{messages.TAC_10}</p>
              <p>{messages.TAC_11}</p>
              <p>{messages.TAC_12}</p>
              <p>{messages.TAC_13}</p>
              <p>{messages.TAC_14}</p>
              <h3>{messages.TAC_GIVERNING_LAW}</h3>
              <p>{messages.TAC_15}</p>
              <p>{messages.TAC_16}</p>
              <h3>{messages.TAC_CHANGES}</h3>
              <p>{messages.TAC_17}</p>
              <p>{messages.TAC_18}</p>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
