export default {
  EVERYDAYCHECK: "everyday",
  LOGIN: "Log in",
  SIGNUP: "Sign up",
  GO_TO_APP: "Go to App",
  OR: "or",

  QUOTE0: "Every day, ",
  QUOTE1: "it gets a little easier.",
  QUOTE2: "But you gotta do it every day, that's the hard part.",

  DOIT: "Do it every day!",
  DOIT_ANDROID: "Get everyday for Android ",
  DOIT_IOS: "Get everyday for iPhone ",

  SLOGAN: "Simple and beautiful habit tracker",
  DESCRIPTION: "Form new habits to achieve your goals",
  FEATURES: "Features",
  L_FEAT_T1: "Do it every day",
  L_FEAT_D1: `Get disciplined. Doing it every day helps you form new habits, and habits are what
              make you reach your goals`,
  L_FEAT_T2: "Don't break the chain",
  L_FEAT_D2:
    "The more days you manage to chain in a row, the less likely you are to quit",
  L_FEAT_T3: "Visualize your progress",
  L_FEAT_D3:
    "Have a quick overview of your goals and streaks in a single beautiful board",

  DEV_EVERYDAY: "every day,",
  DEV_EVERYWHERE: "everywhere",
  DEV_IPHONE: "iPhone",
  DEV_ANDROID: "Android",
  DEV_DEVICES: `Get everyday on all your devices: Web, Mobile and browser extensions.
                Synchronized. See `,
  DEV_DEVICES_MORE: "devices.",
  DEV_TO_IPHONE: "Never mind",
  DEV_TO_ANDROID: "Android, please",

  MADE_USING: "Built doing a little bit ",
  BY: "by ",
  IN: "in ",
  WAIT: "Using everyday, of course! :D",
  EVERY_DAY: "every day",
  BARCELONA: "Barcelona ",

  H_MAIN: "everyday | Habit tracker to help you form good habits",
  H_LANDING: "A simple habit tracker to help you form good habits",
  H_ABOUTEDC: "About everyday",
  H_ABOUTME: "About me",
  H_CONTACT: "Contact",
  H_FAQ: "FAQ",
  H_FEATURED: "Featured in",
  H_FEATURES: "Features",
  H_PRICING: "Pricing",
  H_PRINCIPLES: "Principles",
  H_PRIVACY: "Privacy",
  H_TERMS: "Terms and Conditions",
  H_NOTFOUND: "Page not found",
  H_SIGNUP: "Sign up",
  H_LOGIN: "Log in",
  H_DEVICES: "Devices",

  H_DESCRIPTION: `Easily track your habits to achieve your goals. Get disciplined.
                  Simple and beautiful boards to help you get motivated and do things EVERY DAY.
                  Try it for free!`,
  H_DESC_ABOUTEDC: `Learn more about the minimalist habit tracker and how to make the most out of
                    it to form new habits and reach your goals.`,
  H_DESC_ABOUTME: `Learn more about me, Joan Boixadós, the maker of everyday, the simple and
                   beautiful habit tracker to help you build new habits.`,
  H_DESC_CONTACT: `Contact everyday to learn more about how to track your habits, for support,
                   or to give me any feedback to make the app even better!`,
  H_DESC_FAQ: `Read the Frequently Asked Questions to learn how to make the best of using
               everyday to track your habits and achieve your goals by doing it EVERY DAY!`,
  H_DESC_FEATURED: `Find out where has everyday, the minimalist habit tracker, been featured.
                    See how much people is liking the product to form new habits.`,
  H_DESC_FEATURES: `Learn more about the minimalist habit tracker features to help you form new
                    habits and improve your lifestyle. Change your life with good habits.`,
  H_DESC_PRICING:
    "Learn more about the pricing for everyday, the minimalist habit tracker.",
  H_DESC_PRIVACY: `Read the privacy policies for everyday, the minimalist habit tracker. Make
                  sure to understand what using the service means for you.`,
  H_DESC_TERMS: `Read the terms and conditions for everyday, the beautiful and simple habit
                 tracker. Make sure to understand what using the app means for you.`,
  H_DESC_NOTFOUND: `This page doesn't exist on everyday! If you end up here, make sure to go
                    back to the index page to start forming new habits!`,
  H_DESC_SIGNUP: `Sign up to everyday to start forming new habits and reach your goals by
                  doing things EVERY DAY!`,
  H_DESC_LOGIN: `Log in everyday to track your habits and enhance your life by forming new
                 good habits.`,
  H_DESC_DEVICES: `Get everyday on all your devices: Web, Mobile and browser extensions.
                Synchronized.`,

  SIGNUP_TITLE: "Start doing it every day!",
  S_NAME: "Name",
  S_EMAIL: "Email",
  S_PASSWORD: "Password",
  LOGIN_TITLE: "Log in",
  L_FORGOT: "Forgot your password?",

  CONDITIONS: "By signing up you are accepting everyday's ",
  CONDITIONS_0: "Terms and Conditions ",
  CONDITIONS_1: "and ",
  CONDITIONS_2: "Privacy Policy.",

  FORGOT_TITLE: "Forgot your password?",
  FORGOT_TEXT: `No worries, we will send you an email with instructions on
                how to recover your account.`,
  SEND_EMAIL: "Send email",
  F_LOGIN: "Take me back to log in",
  EMAIL_SENT:
    "If an account exists for this email, a recovery email has been sent",
  RESET_TITLE: "Reset password",
  R_PASSWORD: "New password",
  R_CONFIRM_PASSWORD: "Confirm new password",
  R_RESET_PASSWORD: "Reset password",
  E_PW_MATCH: "Passwords don't match",

  CHECK_EMAIL: `Yaaay! You should soon receive an email to confirm the account and
                start doing things EVERY DAY!`,
  LAST_STEP: "Woohoo! Account created!",
  LAST_STEP_2: "Last step, I promise :-)",
  FREE: "Everyday is free.",
  FOREVER: "Forever.",
  WHYNOTFREE: "Why isn't everyday free?",
  BECAUSE: "A project has to be sustainable, or shut down.",
  BECAUSE_2: `Subscriptions ensure that the costs to run everyday are covered,
              that the app will be there helping us EVERY DAY, forever, and motivates me
              to keep improving it with your feedback.`,
  BECAUSE_3: "It shows you care.",
  BECAUSE_4: "Not convinced? Read ",
  BECAUSE_5: "what Maciej from Pinboard wrote on the topic",
  BECAUSE_6: " or shoot me an ",
  BECAUSE_7: "email!",

  F_PRODUCT: "Product",
  F_FEATURES: "Features",
  F_DEVICES: "Devices",
  F_PRINCIPLES: "Principles",
  F_PRICING: "Pricing",
  F_SIGNUP: "Sign up",
  F_LOGIN2: "Log in",
  F_COMPANY: "Company",
  F_ABOUT_EDC: "About everyday",
  F_ABOUT_ME: "About me",
  F_BLOG: "Blog",
  F_FEATURED: "Featured in",
  F_PRESSKIT: "Press Kit",
  F_SUPPORT: "Support",
  F_FAQ: "FAQ",
  F_CONTACT: "Contact",
  F_TERMS: "Terms and Conditions",
  F_PRIVACY: "Privacy",
  F_FOLLOW: "Follow",
  F_TWITTER: "Twitter",
  F_FACEBOOK: "Facebook",
  F_GOOGLE: "Google+",
  F_INSTAGRAM: "Instagram",
  F_PINTEREST: "Pinterest",

  TERMS_AND_CONDITIONS: "Terms and Conditions",
  TAC_SUBTITLE: "Last updated: April 13, 2021",
  TAC_01: `Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully
           before using the https://everyday.app website (the "Service") operated by
           Everyday Growth SL ("us", "we", or "our").`,
  TAC_02: `Your access to and use of the Service is conditioned on your acceptance of and compliance
           with these Terms. These Terms apply to all visitors, users and others who access or use
           the Service.`,
  TAC_03: `By accessing or using the Service you agree to be bound by these Terms. If you disagree
           with any part of the terms then you may not access the Service. This Terms & Conditions
           agreement is licensed by TermsFeed to everyday.`,
  TAC_PRODUCT: "Product",
  TAC_P_01: `everyday is a subscription based digital service. everyday reserves
             the right to change the products' price at any time. everyday reserves the
             right to refuse or cancel certain orders at our sole discretion. everyday
             might offer contests, discounts or promotions on the website.`,
  TAC_P_02: `everyday reserves the right to update subscription plans benefits at any time. everyday accepts both credit card and paypal as
             payment methods.`,
  TAC_P_021: `everyday Premium entitles you with full access to everyday's premium features on all platforms.`,
  TAC_P_022: `If you upgrade from the free version to any paid plan, we will immediately bill you for the applicable subscription fees. everyday will automatically renew your subscription for the same plan length unless you cancel your subscription from your Account Settings (https://app.everyday.app/settings) at least 24 hours before the end of the current period. An email or phone request to cancel your subscription is not considered cancellation. Any cancellation will take effect only at the end of your then current contract period.`,
  TAC_P_023: `everyday holds a NO REFUND POLICY. Everyday is free to try for an unlimited amount of time and thus subscriptions to the service are non-refundable. All sales are final.`,
  TAC_P_024: `A few exceptions apply to the No Refund Policy:`,
  TAC_P_025: `- Demonstrable technical issues on Premium features. Applies up to 7 days after the purchase of the service.`,
  TAC_P_026: `- Automatic plan renewals up to 7 days prior to the refund request for accounts showing no activity. Remember, subscriptions can be easily cancelled at  any time from https://app.everyday.app/settings .`,
  TAC_P_027: `everyday reserves the right to offer refunds in select circumstances at its sole discretion.`,
  TAC_P_03: `everyday reserves the right to terminate access to certain users in case of 
             abuse or misuse of the platform.`,
  TAC_P_04:
    "everyday exclusively owns the content and the trademarks displayed on the app.",
  TAC_EXCLUSION_OF_LIABILITY: "Exclusion of liability",
  TAC_EOL_01: `To the fullest extent permitted by applicable laws we disclaim liability for any
               losses and expenses of whatever nature and howsoever arising including, without
               limitation, any direct, indirect, general, special, punitive, incidental or
               consequanetial damages; loss of use; loss of data; loss caused by a virus; loss of
               income or profit; loss of or damage to property; claims of third parties; or other
               losses of any kind or character, even if we have been advised of the possibility of
               such damages or losses, arising out of or in connection with the use of the sites or
               any web site with which they are linked, or any merchandise available on the site.
               You assume total responsability for establishing such procedures for data back up and
               virus checking as you consider necessary. This limitation of liability applies
               whether alleged liability is based on contract, tort (including negligence), strict
               liability or any other basis.`,
  TAC_EOL_02: `OUR WEBSITE IS OPERATED BY everyday ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT
               REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW,
               everyday SPECIFICALLY DISCLAIMS ALL WARRANTIES 
               AND CONDITIONS OF ANY KIND, INCLUDING
               ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
               PURPOSE, TITLE AND NONINFRINGEMENT FOR OUR WEBSITE AND ANY CONTRACTS AND SERVICES YOU
               PURCHASE THROUGH IT. everyday SHALL 
               NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY
               ERRORS OR OMISSIONS IN THE CONTENT OF OUR WEBSITE, FOR CONTRACTS OR SERVICES SOLD
               THROUGH OUR WEBSITE, FOR YOUR ACTION OR INACTION IN CONNECTION WITH OUR WEBSITE OR
               FOR ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER DAMAGE YOU MAY INCUR IN
               CONNECTION WITH OUR WEBSITE. YOUR USE OF OUR WEBSITE AND ANY CONTRACTS OR SERVICES
               ARE AT YOUR OWN RISK. IN NO EVENT
               SHALL EITHER everyday OR THEIR AGENTS BE LIABLE
               FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
               ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OUR WEBSITE, CONTRACTS AND
               SERVICES PURCHASED THROUGH OUR WEBSITE, THE DELAY OR INABILITY TO USE OUR WEBSITE
               OR OTHERWISE ARISING IN CONNECTION WITH OUR WEBSITE, CONTRACTS OR RELATED SERVICES,
               WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF
               THE POSSIBILITY OF ANY SUCH DAMAGES. 
               IN NO EVENT SHALL everyday’ LIABILITY FOR ANY
               DAMAGE CLAIM EXCEED THE AMOUNT PAID BY YOU TO
               everyday FOR THE TRANSACTION GIVING
               RISE TO SUCH DAMAGE CLAIM.`,
  TAC_EOL_03: `SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
               CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.`,
  TAC_EOL_04: `WITHOUT LIMITING THE FOREGOING, everyday DO NOT REPRESENT OR WARRANT THAT THE
               INFORMATION ON THE WEBITE IS ACCURATE, COMPLETE, RELIABLE, USEFUL, TIMELY OR CURRENT
               OR THAT OUR WEBSITE WILL OPERATE WITHOUT INTERRUPTION OR ERROR.`,
  TAC_EOL_05: `YOU AGREE THAT ALL TIMES, YOU WILL LOOK TO ATTORNEYS FROM WHOM YOU PURCHASE SERVICES
               FOR ANY CLAIMS OF ANY NATURE, INCLUDING LOSS, DAMAGE, OR WARRANTY. everyday AND
               THEIR RESPECTIVE AFFILIATES MAKE NO REPRESENTATION OR GUARANTEES ABOUT ANY CONTRACTS
               AND SERVICES OFFERED THROUGH OUR WEBSITE.`,
  TAC_EOL_06: `everyday MAKES NO REPRESENTATION THAT CONTENT PROVIDED ON OUR WEBSITE,
               CONTRACTS, OR RELATED SERVICES ARE APPLICABLE OR APPROPRIATE FOR USE IN ALL
               JURISDICTIONS.`,
  TAC_ACCOUNTS: "Accounts",
  TAC_04: `When you create an account with us, you must provide us information that is accurate,
           complete, and current at all times. Failure to do so constitutes a breach of the Terms,
           which may result in immediate termination of your account on our Service.`,
  TAC_05: `You are responsible for safeguarding the password that you use to access the Service and
           for any activities or actions under your password, whether your password is with our
           Service or a third-party service.`,
  TAC_06: `You agree not to disclose your password to any third party. You must notify us
           immediately upon becoming aware of any breach of security or unauthorized use of your
           account.`,
  TAC_LINKS: "Links To Other Websites",
  TAC_07: `Our Service may contain links to third-party web sites or services that are not owned or
           controlled by everyday.`,
  TAC_08: `everyday has no control over, and assumes no responsibility for, the content,
           privacy policies, or practices of any third party web sites or services. You further
           acknowledge and agree that everyday shall not be responsible or liable, directly or
           indirectly, for any damage or loss caused or alleged to be caused by or in connection
           with use of or reliance on any such content, goods or services available on or through
           any such web sites or services.`,
  TAC_09: `We strongly advise you to read the terms and conditions and privacy policies of any
           third-party web sites or services that you visit.`,
  TAC_TERMINATION: "Termination",
  TAC_10: `We may terminate or suspend access to our Service immediately, without prior notice or
           liability, for any reason whatsoever, including without limitation if you breach the
           Terms.`,
  TAC_11: `All provisions of the Terms which by their nature should survive termination shall
           survive termination, including, without limitation, ownership provisions, warranty
           disclaimers, indemnity and limitations of liability.`,
  TAC_12: `We may terminate or suspend your account immediately, without prior notice or liability,
           for any reason whatsoever, including without limitation if you breach the Terms.`,
  TAC_13: `Upon termination, your right to use the Service will immediately cease. If you wish to
           terminate your account, you may simply discontinue using the Service.`,
  TAC_14: `All provisions of the Terms which by their nature should survive termination shall
           survive termination, including, without limitation, ownership provisions, warranty
           disclaimers, indemnity and limitations of liability.`,
  TAC_GOVERNING_LAW: "Governing Law",
  TAC_15: `These Terms shall be governed and construed in accordance with the laws of Spain, without
           regard to its conflict of law provisions.`,
  TAC_16: `Our failure to enforce any right or provision of these Terms will not be considered a
           waiver of those rights. If any provision of these Terms is held to be invalid or
           unenforceable by a court, the remaining provisions of these Terms will remain in effect.
           These Terms constitute the entire agreement between us regarding our Service, and
           supersede and replace any prior agreements we might have between us regarding the
           Service.`,
  TAC_CHANGES: "Changes",
  TAC_17: `We reserve the right, at our sole discretion, to modify or replace these Terms at any
           time. If a revision is material we will try to provide at least 30 days notice prior to
           any new terms taking effect. What constitutes a material change will be determined at
           our sole discretion.`,
  TAC_18: `By continuing to access or use our Service after those revisions become effective, you
           agree to be bound by the revised terms. If you do not agree to the new terms, please stop
           using the Service.`,

  PRIVACY: "Privacy",
  P_SUBTITLE: "Last updated: April 13, 2021",
  P_01: `Everyday Growth SL ("us", "we", or "our") operates the https://everyday.app website
         (the "Service").`,
  P_02: `This page informs you of our policies regarding the collection, use and disclosure of
         Personal Information when you use our Service.`,
  P_03: `We will not use or share your information with anyone except as described in this
         Privacy Policy. This Privacy Policy is licensed by TermsFeed Generator to everyday.`,
  P_04: `We use your Personal Information for providing and improving the Service. By using the
         Service, you agree to the collection and use of information in accordance with this
         policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy
         Policy have the same meanings as in our Terms and Conditions, accessible at
         https://everyday.app`,
  P_ICAU: "Information Collection And Use",
  P_05: `While using our Service, we may ask you to provide us with certain personally identifiable
         information that can be used to contact or identify you. Personally identifiable
         information may include, but is not limited to, your name ("Personal Information").`,
  P_LOG: "Log Data",
  P_06: `We collect information that your browser sends whenever you visit our Service ("Log Data").
         This Log Data may include information such as your computer's Internet Protocol ("IP")
         address, browser type, browser version, the pages of our Service that you visit, the time
         and date of your visit, the time spent on those pages and other statistics.`,
  P_OTHER: "Other",
  P_O_01: `everyday stores the email address and the name of those who sign up. everyday
           won't publish nor sell any of this data. everyday uses google analytics on the
           landing page at https://everyday.app to track visits. everyday may use the
           provided email addresses for newsletters or updates on the application. There will
           always be the option to opt out of this kind of emails. everyday will enforce the
           privacy of the data introduced in the application. `,
  P_O_02: `EverydayCheck runs on dedicated servers that only I have access to. Wherever possible,
           the site uses SSL. My goal is to have the site be SSL by default as soon as practicable.
           I do not share individual user data with third parties under any circumstances. The site
           will never show ads. The site keeps third party content to the bare minimum, google
           analytics on certai public pages, fonts and payment buttons. Google Analytics scripts
           are not served to logged-in users. If you spot a privacy leak, please report it
           discreetly to joan@everyday.app.`,
  P_O_03: "Please do not store truly sensitive information on everyday",
  P_COOKIES: "Cookies",
  P_07: `Cookies are files with small amount of data, which may include an anonymous unique
         identifier. Cookies are sent to your browser from a web site and stored on your
         computer's hard drive.`,
  P_08: `We use "cookies" to collect information. You can instruct your browser to refuse all
         cookies or to indicate when a cookie is being sent. However, if you do not accept
         cookies, you may not be able to use some portions of our Service.`,
  P_SERVICE: "Service Providers",
  P_09: `We may employ third party companies and individuals to facilitate our Service, to
         provide the Service on our behalf, to perform Service-related services or to assist
         us in analyzing how our Service is used.`,
  P_10: `These third parties have access to your Personal Information only to perform these
         tasks on our behalf and are obligated not to disclose or use it for any other purpose.`,
  P_SECURITY: "Security",
  P_11: `The security of your Personal Information is important to us, but remember that no
         method of transmission over the Internet, or method of electronic storage is 100% secure.
         While we strive to use commercially acceptable means to protect your Personal Information,
         we cannot guarantee its absolute security.`,
  P_LINKS: "Links To Other Sites",
  P_12: `Our Service may contain links to other sites that are not operated by us. If you click on
         a third party link, you will be directed to that third party's site. We strongly advise
         you to review the Privacy Policy of every site you visit.`,
  P_13: `We have no control over, and assume no responsibility for the content, privacy policies
         or practices of any third party sites or services.`,
  P_CHILDREN: "Children's Privacy",
  P_14: 'Our Service does not address anyone under the age of 13 ("Children").',
  P_15: `We do not knowingly collect personally identifiable information from children under 13.
         If you are a parent or guardian and you are aware that your Children has provided us with
         Personal Information, please contact us. If we discover that a Children under 13 has
         provided us with Personal Information, we will delete such information from our servers
         immediately.`,
  P_CHANGES: "Changes To This Privacy Policy",
  P_16: `We may update our Privacy Policy from time to time. We will notify you of any changes by
         posting the new Privacy Policy on this page.`,
  P_17: `You are advised to review this Privacy Policy periodically for any changes. Changes to
         this Privacy Policy are effective when they are posted on this page.`,

  CONTACT: "Contact",
  C_SUBTITLE: "I'll be glad to help you out with any questions",
  C_01: "The best way to get in touch is to shoot me an email at ",
  C_02: " or to tweet to ",
  C_03: " I'll try to answer ASAP in any case!",

  FAQ: "Frequently Asked Questions",
  FAQ_SUBTITLE: "including Rarely Asked Questions too!",
  FAQ_TOC: "Table of Contents",
  FAQ_EDC: "About everyday",
  FAQ_FEATURES: "Features",
  FAQ_SUPPORT: "Support",
  FAQ_PRIVACY: "Privacy & Payments",
  FAQ_Q1: "What is everyday?",
  FAQ_A1: `everyday is a simple and beautiful habit tracker, an application that helps you
           track your habits and get disciplined. everyday's habit board provides an at a
           glance overview of all the habits you are pushing for making it easy to keep them in
           mind whilst seeing which ones you are doing good at and which ones you need to push
           harder for. It helps you make yourself accountable of your own goals and progress.`,
  FAQ_Q2: "How is everyday going to help me achieve my goals?",
  FAQ_A2: `The premise is that the best way to reach your goals is to work on them EVERY DAY.
           Of course, that's easier said than done. But taking from the great quote "Every day
           it gets a little easier, but you gotta do it every day, that's the hard part",
           we understand that if we manage to do things every day, to form new habits, it will
           become gradually easier and we will succeed.`,
  FAQ_A2_2: `everyday helps us form chains, consecutive days in which we have done an habit.
             The idea that the more days in a row we manage to fulfill a task (e.g do 50 pushups)
             the longer the chain becomes and thus, we are more motivated to keep increasing our
             streak. We just make that extra effort to keep the chain alive! In other words,
             don't break the chain for as long as you can and without noticing, you'll have
             embraced such task into your daily routine, helping you towards your goals.`,
  FAQ_A2_3: `everyday increases the color tone by every new chained day, which adds a little
             bit of motivation and ultimately makes our board look beautiful. The more we do,
             the prettier it looks!`,
  FAQ_A2_4: `everyday sends email reminders the most important of which is the "don't skip
             twice" which helps you remind not to skip doing an habit twice. Breaking a chain
             after a few days it's normal, just forgive yourself and start a new one! It's much
             easier to lose a routine than to form a new one, so reminders help you make sure not
             to lose the so hardly earned progress!`,
  FAQ_A2_5: "If you measure it, you can improve it.",
  FAQ_Q3: "How can I make the best out of tracking my habits with everyday?",
  FAQ_A3:
    "Let's be honest here, the most important thing here is commitment from your side.",
  FAQ_A3_2: `With that being said, my recommendation would be to start by adding few habits you
             would like to form and make sure to read them at least once a day. A good way to make
             sure of that is to either add the application as a pinned tab on your web browser, or
             to set it as your homepage. The first step is to have such goals in mind as much as
             possible.`,
  FAQ_A3_3: `Then, it's time to get started, it will sound dumb but, just start. Your goal is
             50 pushups a day and you did 5 today? That's already an awesome job!! Just go and mark
             the habit on everyday. This is very important, do a little bit EVERY DAY, no
             matter how little. Some days you will feel you haven't really accomplished your daily
             goal, while some other you'll do more than expected. That's normal. Just make sure to
             do it as many days as possible without breaking the chain.`,
  FAQ_A3_4: `I know, it's very hard to do things every day, there are weekends, holidays, etcetera.
             We all skip days. Again, that's normal. If you skip a day, breaking your so hardly
             accomplished long chain, don't worry. Forgive yourself and start a new one as soon as
             possible. I can't stress enough how important it is to not skip twice. everyday
             helps in that regard with reminders.`,
  FAQ_A3_5: `Finally, this is one of the ways in which I use everyday that I think it's
             valuable sharing. We all have big goals/dreams that are easy to overlook on our busy
             daily lives. These might even be very hard to track, maybe they are not even
             quantifiable. For example, one of my habits is "become a better person". What can I
             do every day to become a better person? It's not so easy to track. So, the way I use
             everyday is to have some "short-term habits" with one color, and other
             "long-term goals" in another color. I then divide my long-term goals into more
             quantifiable short-term goals. I try to divide and conquer my more abstract goals.
             For example, "help someone", "learn something", "write", "listen to others",
             "be honest", etcetera. These are much easier to go through every day and think "have
             I helped someone today?". I like it because it helps me think every day on what I want
             to accompish in the long run and work towards it on a daily basis.`,
  FAQ_A3_6: `At the time of writing this I have 23 habits on my list, just so you get an idea.
             everyday is very flexible. Just find the way it works best for you!`,
  FAQ_Q4: "Which are some good habits to begin with?",
  FAQ_A4: `This really depends on your goals. You might want to start with some habits you have
           already been working on but you still don't consider part of your routine.`,
  FAQ_A4_1: "I'll give some general ideas:",
  FAQ_A4_2: "Meditate",
  FAQ_A4_3: "Talk to a stranger",
  FAQ_A4_4: "Wake up early",
  FAQ_A4_5: "Write",
  FAQ_A4_6: "Go for a walk",
  FAQ_A4_7: "Learn something",
  FAQ_A4_8: "Cook my own food",
  FAQ_A4_9: "Read",
  FAQ_A4_10: "Work out",
  FAQ_A4_11: "Eat healthy",
  FAQ_A4_12: "Play an instrument",
  FAQ_A4_13: "Have a good attitude",
  FAQ_A4_14: "Work on everyday ( ;D )",
  FAQ_Q5: "What is the application developed in?",
  FAQ_A5: "Beware! This question is for techies :P",
  FAQ_A5_2: `The application uses React, Redux, Webpack and SASS on the frontend and Koa (Node.js),
             Knex.js and MySQL on the backend. The webserver is Caddy.`,
  FAQ_A5_3: "The mobile application is developed with React Native.",
  FAQ_Q6: "What are the numbers on the right side?",
  FAQ_A6: `On the right side you can see three columns of numbers. "Current streak" is the number of
           days you have managed to chain the habit until today. This is the number you have to keep
           increasing! "Longest streak" is your personal record of the highest number of days you
           have ever managed to chain such habit. This is the number you have to beat! Finally, the
           third column is a count of the total of times you've done such habit.`,
  FAQ_A6_2: `By moving the slider you can see the counts by year, month and week. Finally, you can
             also see the yearly, monthly and weekly completion rates.`,
  FAQ_Q7: "What are the numbers under the board?",
  FAQ_A7: `These numbers are just a count of the number of habits you have completed each day. These
           numbers are useful to compare in a day to day basis. Did I complete more habits today
           than yesterday? It's just another way to keep accountable of your progress.`,
  FAQ_Q8: "Can I define the order in which I see the habits on the board?",
  FAQ_A8: "Yes, just click on an habit name and drag it up or down.",
  FAQ_Q9: "How am I supposed to use the color options?",
  FAQ_A9: `My idea with everyday is to make it as minimal and flexible as possible. In the end
           the power of paper is flexibility, so we need o try to make applications get as close to
           that as possible. The option to use colors allows you to use them as you wish. Some
           people will just use the color scale they like better while other's will use it as
           separators, or to categorize habits by type. For example, habits related to health and
           fitness can be green, while habits related to reading and learning can be blue. The
           important thing is to get to have a pretty board because it will mean you are doing a
           damn good job!`,
  FAQ_Q10: "Can I share my boards with everyone?",
  FAQ_A10: `Not yet (as of June 2018). But this is certainly something that I want to bring into
            everyday. Sharing our progress helps motivate us. If you would really like to have
            this feature please make sure to contact me at joan@everyday.app and let's talk
            about it, I really want to learn from you how do you  expect this to work. For example,
            the option to hide some habits from the public board might be relevant.`,
  FAQ_Q11: "Does everyday work on mobile?",
  FAQ_A11: `Yes! As a web application you can access everyday from your mobile phone browser
            and mark habits from there! It's responsive design adapts the board to your mobile
            phone screen. However, the beauty of the board is mostly lost so I recommend using the
            mobile application that allows for more flexibility while preserving the minimalist and
            beautiful approach.`,
  FAQ_Q12: "Does everyday have a native mobile application?",
  FAQ_A12: `As of June 2018: Yes it does! Find a link to it on the devices section of this website
            and let me know what you think!`,
  FAQ_Q13: "Is there any option to have the application in another language?",
  FAQ_A13: `At the moment the application only exists in english. It's prepared to handle other
            languages but right now I am waiting to see how critical it is. If you'd like to see
            the application in your language, make sure to let me know at`,
  FAQ_A13_2: "joan@everyday.app!",
  FAQ_Q14:
    "There are habits I do on specific days of the week, will everyday support them?",
  FAQ_A14: `This is an issue that comes up every once in a while. I can't say the application will
            never support it but right now it's not in the roadmap. Since my goal is to have a
            beautiful, minimal and flexible application this feature would pose a real threat.
            The main idea of everyday is to target things that must be done EVERY DAY.
            However, the skip option allows for enough flexibility, so that you can skip the days
            the habit doesn't apply in. With the "no weekends" option, everyday will automatically
            skip weekends for you so that you can focus on your every weekday habits :)`,
  FAQ_Q15: "There's a feature I'd really like to have. Can you implement it?",
  FAQ_A15: "Let's talk about it! Shoot me an email at",
  FAQ_A15_2: "joan@everyday.app!",
  FAQ_Q16: `I like the minimalist approach. How will you deal with adding new features versus
            keeping it simple?`,
  FAQ_A16: `As a computer engineer and product person it's very easy for me to just come up with
            new solutions, features, etcetera. However, what brought me substantial success with
            everyday was to learn to discard features, to keep the application as simple and
            beautiful as possible while preserving it's flexibility. This is what my users value
            and this is my goal number one. I'll really make sure to not clutter the application
            with useless features or features that only make happy a very small % of the user base.
            I don't need to keep adding features to justify the price of the application. The main
            goal of the application is to really make it easy for the users to benefit from using
            it.`,
  FAQ_Q17: "I have forgotten my password, what do I do?",
  FAQ_A17:
    "No worries! Just recover access to your account and set a new password visiting ",
  FAQ_A17_2: "https://everyday.app/forgot",
  FAQ_Q18: "How do I turn off the email reminders?",
  FAQ_A18: "There are two ways to turn email reminders off:",
  FAQ_A18_2: `From any of the emails itself, in the footer, you can either turn the specific type
              of email reminder off or turn all email reminders off.`,
  FAQ_A18_3: `From the application itself, clicking on the dropdown menu under your name on the
              top right corner, you can navigate to the settings menu. From there you can manage
              your email reminders and turn them on/off.`,
  FAQ_Q19: "How do I unsubscribe from the mailing list?",
  FAQ_A19: `From the application, clicking on the dropdown menu under your name on the top right
            corner, you can navigate to the settings menu. From there you can manage your mailing
            list subscription.`,
  FAQ_SWE_Q1: "How do I install everyday's web extension in Google Chrome?",
  FAQ_SWE_A11: "Go to the ",
  FAQ_SWE_A111: "Google Web Store.",
  FAQ_SWE_A12: 'Click the "Add to Chrome" button on the top right side.',
  FAQ_SWE_A13: "Confirm you want to add everyday web extension.",
  FAQ_SWE_A14:
    "Try that it works by opening a new tab! It should load your board :)",
  FAQ_SWE_Q2: "How do I uninstall everyday's web extension in Google Chrome?",
  FAQ_SWE_A2: 'Navigate to "chrome://extensions/" in your browser.',
  FAQ_SWE_A21:
    "Search for everyday in the list and click on the trash bin icon.",
  FAQ_SWE_Q3: "How do I install everyday's web extension in Mozilla Firefox?",
  FAQ_SWE_A31: "Go to the ",
  FAQ_SWE_A311: "Add-ons for Mozilla Firefox website.",
  FAQ_SWE_A32: 'Click the "Add to Firefox" button.',
  FAQ_SWE_A33: "Confirm you want to add everyday web extension.",
  FAQ_SWE_A34:
    "Try that it works by opening a new tab! It should load your board :)",
  FAQ_SWE_Q4: "How do I uninstall everyday's web extension in Mozilla Firefox?",
  FAQ_SWE_A4: 'Navigate to "about:addons" in your browser.',
  FAQ_SWE_A41:
    "Search for everyday in the list and click on the remove button.",
  FAQ_SQ1: "How do I set everyday as my browsers homepage?",
  FAQ_SA1_1: "In Google Chrome:",
  FAQ_SA1_11: `In the top right corner of your computer's browser, click More (three dots) and
               then Settings.`,
  FAQ_SA1_12: 'In the "Appearance" section, turn on Show home button.',
  FAQ_SA1_13: "Select the second option, then enter https://app.everyday.app.",
  FAQ_SA1_2: "In Firefox:",
  FAQ_SA1_21: "Open your browser and visit https://app.everyday.app.",
  FAQ_SA1_22: "Drag the tab to the Home button, then release.",
  FAQ_SA1_23: "Click Yes.",
  FAQ_SA1_3: "In Safari:",
  FAQ_SA1_31: `In the top left corner of your screen, select Safari and then Preferences and
               then General.`,
  FAQ_SA1_32:
    'Next to "New windows open with" and "New tabs open with," select Homepage.',
  FAQ_SA1_33: 'Next to "Homepage," enter https://app.everyday.app.',
  FAQ_SA1_4: "In Internet Explorer:",
  FAQ_SA1_41: "In the menu bar on the top of your browser, click Tools.",
  FAQ_SA1_42: "Select Internet Options.",
  FAQ_SA1_43: "Click the General tab.",
  FAQ_SA1_44:
    'In the "Home page" section, enter https://app.everyday.app in the text box.',
  FAQ_SA1_45: "Click OK.",
  FAQ_SA1_46: "Restart your browser.",
  FAQ_SA1_5: "In Android browser",
  FAQ_SA1_51: "Open your browser app, like Internet or Browser.",
  FAQ_SA1_52:
    "On your phone or in the top right of the browser, tap More (three dots).",
  FAQ_SA1_53: "Tap Settings and then Homepage and then Other web page.",
  FAQ_SA1_54: "Enter https://app.everyday.app.",
  FAQ_SA1_55: "Tap OK.",
  FAQ_SQ2: "How do I bookmark everyday's app?",
  FAQ_SA2: `It's important to note that the app itself resides under
            https://app.everyday.app and so, this should be the url to bookmark. I take for
            granted that almost everyone knows how to bookmark a site. However,
            you can find detailed instructions for your browser `,
  FAQ_SA2_2: "here.",
  FAQ_SQ3: "How do I set everyday as a pinned tab in my browser?",
  FAQ_SA3: `Pinned Tabs allow you to pin any website to the left side of the Tab Strip so that
            it's always available.`,
  FAQ_SA3_2:
    'Control-click on the tab you want to pin and select "Pin Tab" from the menu.',
  FAQ_SQ4: "How do I create a desktop shortcut to everyday?",
  FAQ_SA4_1: `Resize the browser window so you can see both your computer's desktop and the
              browser window on the same screen.`,
  FAQ_SA4_2: "Navigate to https://app.everyday.app and select the url.",
  FAQ_SA4_3:
    "Drag the selected url to your desktop. It should create the shortcut.",
  FAQ_SQ5:
    "How do I create a homescreen shortcut to everyday on my phone/tablet?",
  FAQ_SA5: `Adding a shortcut to your phone/tablet's homescreen gives it more visibility and ease
            of access for you to keep accountable of your habits.`,
  FAQ_SA5_1: "In Android:",
  FAQ_SA5_11: "Open Chrome and navigate to https://app.everyday.app.",
  FAQ_SA5_12: 'Tap the menu button and tap "add to homescreen".',
  FAQ_SA5_2: "In iPad/iPhone:",
  FAQ_SA5_21: "Open Safari and navigate to https://app.everyday.app.",
  FAQ_SA5_22:
    'Tap the "share button" (the rectangle with an arrow pointing upward)',
  FAQ_SA5_23: 'Give the shortcut a name, i.e "everyday".',
  FAQ_SA5_24: 'Tap "Add button".',
  FAQ_Q20: "How much does it cost to subscribe?",
  FAQ_A20: `Everyday is free, forever. However, there's the possibility to upgrade to Everyday Premium
            which costs $29.99 per year and grants you access to all the premium features across
            all devices.`,
  FAQ_Q201: "How do I cancel my subscription?",
  FAQ_A201: `It's very easy to manage your subscription. In case you want to unsubscribe,
             you can easily do so by clicking "unsubscribe" from
             the application settings. The application will ALWAYS notify you one month
             before the subscription renews.`,
  FAQ_Q21: "Is my personal information safe?",
  FAQ_A21: `Yes. I am the only person with access to the server. The server is hosted by Amazon, a
            renowned dedicated server company. I do regular backups. I have absolutely no intention
            of sharing your data. Charging to subscribe for the app allows me to cover the costs to
            run everyday without needing to find alternative unmoral business models.`,
  FAQ_Q22: `I like the app but I don’t like to give my credit card details to strangers...
            what can I do?`,
  FAQ_A22: "everyday uses ",
  FAQ_A22_2: "Stripe.com ",
  FAQ_A22_3: ` as a payment method. Stripe.com is a trustworthy payment
            solution that precisely works to solve this kind of issue. By using Stripe, your credit
            card information securely travels between your web browser and Stripe servers.
            everyday never sees any credit card information, it just gets notified by Stripe
            of when a payment has been done.`,
  FAQ_Q23: "What payment methods do you offer?",
  FAQ_A23: `Right now, everyday uses Stripe.com as the unique payment method. This payment
            method ensures your credit card information to be securely sent to their servers
            without the end products (in this case, everyday) ever seeing any of your credit
            card details. This makes it easier for you, and also for me.`,
  FAQ_A23_2: "I am considering allowing payments via Paypal in the future.",
  FAQ_Q24: "Why isn't everyday free?",
  FAQ_A24: "A project has to be sustainable, or shut down.",
  FAQ_A24_2: `Paying $29.99/year ensures that the costs to run everyday are covered, that the app
              will be there helping us EVERY DAY, forever, and motivates me to keep improving it
              with your feedback.`,
  FAQ_A24_3: "It shows you care.",
  FAQ_A24_4: "Not convinced? ",
  FAQ_A24_5: "Read what Maciej from Pinboard wrote on the topic",
  FAQ_A24_6: " or ",
  FAQ_A24_7: "shoot me an email!",
  FAQ_Q25: "How do I delete my account?",
  FAQ_A25: `From the application settings, you have the option to delete your account :( If you, by
            any chance end up deciding to delete your account, I'll appreciate if you give me any
            feedback on how I could have made the application better for you :)`,

  AEDC_ABOUT_EDC: "About everyday",
  AEDC_01: `everyday is a simple and beautiful habit tracker, an application that helps you
            form new habits and get disciplined.`,
  AEDC_02: `everyday's habit board provides an at-a-glance overview of all the habits you
            are pushing for. It helps you make yourself accountable of your own goals and progress
            in a very easy way.`,
  AEDC_03: `The only way to achieve our goals is to keep them in mind all the time and work on them
            EVERY DAY. Based on the premise of 'don't break the chain', everyday helps you get
            motivated to form long streaks of days in which you have done a certain habit you want
            to form. The more days you manage to chain in a row, the less likely you are going to
            quit, until ultimately, you do it without even thinking about it. It has become an
            habit!`,
  AEDC_04: `There are several applications out there working with this idea, but from my point of
            view they are all too cluttered, trying to cover too many things and offering features
            that don't add any real value. My approach with everyday is to do one thing and do
            it right. A minimalistic and beautiful application that remains as close to what we
            would do on a sheet of paper as possible, preserving its flexibility and taking
            advantage of what technology allows us to do without abusing it.`,
  AEDC_05: `In the end, apps cannot cover for the required commitment on our side. All apps can do,
            is to help us keep accountable of our progress, remind us about our goals and motivate
            us to push forward. In everyday, you can instantly visualize your progress, the
            more you do, the prettier it looks. Together with other soft features, like email
            reminders that make sure you don't skip a habit twice in a row, customization and the
            possibility to share your board, everyday really is a good companion to self
            improvement.`,
  AEDC_06: `Finally, I'd like to say that everyday is a "one man band". Absolutely everything
            in everyday is done by me, Joan Boixadós, from Barcelona. It's an adventure of
            self confidence to prove that by working on things every day we can all do great things!
            It started as a tool I developed for personal use and to get disciplined. Now, the
            goal is to try to live from it while making it an even better tool. So, if the app is
            helping you, please consider subscribing to help me get closer to my goal!`,
  AEDC_07: "Every day, it gets a little easier.",
  AEDC_08: "But you gotta do it every day, that's the hard part.",

  AME_TITLE: "About me",
  AME_SUBTITLE: "Hello! :-)",
  AME_01: `I'm Joan Boixadós and I'm from Barcelona. I've lived in Munich and Bergen too. My
           internet handle is mezod. I studied computer science and work in the web application
           development world. I am a product person. I like to create stuff. I keep doing projects
           with the hopes to bootstrap one. everyday is my first real success on the matter.
           I value freedom above all and the idea of having my own little business is very enticing
           to me.`,
  AME_02: "I like to list things, like ",
  AME_03: "films, ",
  AME_04: "music bands ",
  AME_05: "and ",
  AME_06: "books",
  AME_07:
    ", so that I keep track of everything I experience. I also like to solo roadtrip a lot. ",
  AME_08: "I drove 20000km in 80 days and took lots of pictures on the way.",
  AME_09: " As you can see I also enjoy photography.",
  AME_10: `I'm quite geeky when it comes to productivity and self-improvement. Before
           everyday I also implemented `,
  AME_11: "multikanban, a simple kanban for multiple personal projects. ",
  AME_12: `I'm sure I'll revisit it since I find it so useful. everyday was born when I
           realized that many of my daily tasks in the todo list were tasks I wanted to do every day
           and could never cross off, since doing them once didn't affect them being on the list
           again the next day.`,
  AME_13: `I used everyday on my own for a few weeks before realizing how useful it was and
           that others might find value in it. It helped me keep in mind both, the things I wanted
           to do every day, and the long term goals that otherwise could be overlooked on the day to
           day life. After deciding to productize it and share it for free, I got a lot of messages
           of appreciation and support that made me think I should put more time into it. I can't
           live from it yet but after realizing that people finds value in it and pays for it, I
           believe it depends on me, my work and my efforts to get to that point.`,
  AME_14: `As a product person that likes to navigate its way between product management, business
           and software development I like to think I've found my path with indiehackerism.
           Basically, bootstrapping a small company on your own, with only your resources and
           efforts. You don't need to be very good at one thing, but you need to be good at many!
           "Indie" is thriving and I like to think I contributed to this lifestyle with my `,
  AME_15: "awesome-indie repository on GitHub. ",
  AME_16: "A list of resources for independent developers to make money. ",
  AME_17: "You can read about it ",
  AME_18: "here.",
  AME_19: `In any case, I'm very happy with my progress and early success on everyday. I'm
           working on it EVERY DAY and I have learnt a lot from it. I hope the app helps you as much
           as it has helped me!`,
  AME_20: "Joan Boixadós",
  AME_21: "PS: By the way, feel free to get in touch with me for ANYTHING at ",
  AME_22: "joan@everyday.app!",
  AME_23: "Some links:",
  AME_24: "My personal site",
  AME_25: "My blog",
  AME_26: "My twitter",
  AME_27: "My github",
  AME_28: "My listlogs",

  FEAT_TITLE: "Featured in",
  FEAT_SUBTITLE: "List of relevant places featuring everyday",
  FEAT_01_DATE: "20th March 2017",
  FEAT_01: "#1 for over 24h on reddit's r/getdisciplined",
  FEAT_02_DATE: "6th May 2017",
  FEAT_02: "Trendiest startup of the second week of May on BetaList.com",
  FEAT_02_ALT:
    "everyday, trendiest startup of the second week of May on BetaList.com",
  FEAT_03_DATE: "27th of June",
  FEAT_03: "Frontpage of reddit's r/GetMotivated",
  FEAT_04_DATE: "28th of June",
  FEAT_04: "#3 trendiest project on HackerHunt's launch day",
  FEAT_04_ALT: "everyday featured in HackerHunt",
  FEAT_05_DATE: "28th of June",
  FEAT_05: "Frontpage of HackerNews' Show HN",
  FEAT_05_ALT: "everyday, featured in HN's Show HN",
  FEAT_06_DATE: "30th of June",
  FEAT_06: "Top10 of the day in ProductHunt with over 150 upvotes",
  FEAT_06_ALT: "Featured in ProductHunt",
  FEAT_07_DATE: "3rd of July",
  FEAT_07: "Featured in Wwwhats'new, one of the top10 tech blogs in spanish",
  FEAT_08_DATE: "18th of July",
  FEAT_08: "Featured in the great Offscreen Dispatch newsletter",

  PRI_TITLE: "Pricing",
  PRI_TRIAL: "After the 30 day free trial,",
  PRI_PRICE: "$12/year",
  PRI_THIRD_PARTIES: "Payments are handled by ",
  PRI_THIRD_PARTIES_2: ` Your credit card details go straight from your browser to their secure
                    payment system. I never see them!`,
  PRI_SUBTITLE:
    "Form unlimited habits on-the-go with premium minimalist features to help you grow a little bit, every day ;)",
  PRI_F_FREE: "Free",
  PRI_F_PRICE: "$0",
  PRI_F_SUB1: "it's free",
  PRI_F_SUB2: "forever",
  PRI_F_FEAT1: "Up to 3 habits",
  PRI_F_FEAT2: "All habit colours",
  PRI_F_FEAT3: "Unlimited data storage",
  PRI_F_FEAT4: "All devices (Web, iOS, Android)",
  PRI_F_FEAT5: "Web Extensions (Chrome, Firefox)",
  PRI_F_FEAT6: "Yearly data visualization",
  PRI_F_FEAT7: "Reminders (Email + Mobile Push Notifications)",
  PRI_F_FEAT8: "Sort, Skip, Break habits, No-weekends...",
  PRI_F_BTN: "Sign up",
  PRI_P_PREMIUM: "Premium",
  PRI_P_PRICE: "$2.5",
  PRI_P_SUB1: "per month",
  PRI_P_SUB2: "billed annually*",
  PRI_P_FEAT1: "All free features",
  PRI_P_FEAT2: "Unlimited habits",
  PRI_P_FEAT3: "Mobile dark mode",
  PRI_P_FEAT4: "Devices synchronisation (Web, iOS, Android)",
  PRI_P_FEAT5: "Archive habits",
  PRI_P_FEAT6: "Habit insights",
  PRI_P_FEAT7: "All habit history access",
  PRI_P_FEAT8: "Priority support",
  PRI_P_BTN: "Upgrade Now",
  PRI_P_NOTE1: "* That's ",
  PRI_P_NOTE2: "$29.99 ",
  PRI_P_NOTE3: "per year.",
  PRI_P_NOTE4: "When billed month-to-month, ",
  PRI_P_NOTE5: "Premium ",
  PRI_P_NOTE6: "is ",
  PRI_P_NOTE7: "$7.49 ",
  PRI_P_NOTE8: "per month.",
  PRI_L_LIFETIME: "Lifetime",
  PRI_L_PRICE: "$99",
  PRI_L_SUB1: "one time",
  PRI_L_SUB2: "purchase*",
  PRI_L_FEAT1: "All the premium features, forever",
  PRI_L_FEAT2: "Upcoming features in an app under constant active development",
  PRI_L_FEAT3: "Priority feature requests",
  PRI_L_BTN: "Upgrade Now",
  PRI_L_NOTE: "* The amount of issued lifetime licenses is limited.",

  FEA_FEATURES: "Features",
  FEA_SUBTITLE: "Minimalist features to help you do it every day",
  FEA_VISUALIZE: "At-a-glance progress visualization",
  FEA_VISUALIZE_TEXT: `Easily keep accountable and track your habits and streaks from a simple
                       beautiful board. The more you do, the prettier it looks! As close to a
                       traditional habit tracker on paper as it gets.`,
  FEA_REMINDERS: "Reminders to keep you on track",
  FEA_REMINDERS_TEXT: `Email reminders to help you build habits by making sure you don't skip days!
                       The "don't skip twice in a row" reminder is particularly helpful, since in 2
                       days you can lose the progress of weeks.`,
  FEA_WEBEXT: "Always engaged with the web extensions",
  FEA_WEBEXT_TEXT: `Web extensions set everyday as the default website when opening a new tab in
                    your browser. Seeing your board several times every day will serve as the cue
                    to remind you and get you motivated about what you want to do EVERY DAY!`,
  FEA_DEVICES: "Simplicity and beauty brought across devices",
  FEA_DEVICES_TEXT: `Get everyday on more than 5 platforms, including Web, iPad, iPhone and Android
                     devices. Your data is always synchronized, accessible and backed up.`,
  FEA_DEVICES_SEE: "See ",
  FEA_DEVICES_DEVICES: "devices.",
  FEA_CUSTOMIZE: "Customize your board",
  FEA_CUSTOMIZE_TEXT: `Flexibility is important to adapt the tool to your needs. Sorting the habits,
                       you can prioritize them or group them in categories. Colours can not only be
                       used to make your board look prettier to you, but also to group your habits
                       by type. With the 'break habit' option, have chains with descending colours
                       for habits you want to break.`,
  FEA_STATS: "If you measure it, you can improve it",
  FEA_STATS_TEXT: `Simple statistics that mean something to you instantly. Overcome your own streaks
                   by seeing your current streak side to side with your longest streak ever. Use the
                   daily completed habit counter to compare your daily progress. Have an overall
                   idea of your progress with the total counts and completion rates.`,
  FEA_SKIPS: "Flexibility for us, humans",
  FEA_SKIPS_TEXT: `The skip option allows you to skip days without breaking the chain when ill,
                   on holidays or situations out of your control. The skip option can also be used
                   to track habits that aren't daily, giving everyday a lot of flexibility.
                   With 'no weekends', form habits you just want to pursue on weekdays.
                   Use skip at your own peril! :P`,
  FEA_EMOJI: "Use any character type, including emoji",
  FEA_EMOJI_TEXT: `everyday supports a large amount of character types to represent alphabets such
                   as cyrillic, japanese, korean, chinese and arabic amongst others. With emoji, you
                   can personalize your own board for that little extra of motivation :)`,

  NF_TITLE: "404 Page not found",
  NF_01:
    "Whoops! This page doesn't exist. You can get back to everyday by clicking",
  NF_02: "here.",

  DEV_TITLE: "Get everyday on all your devices.",
  DEV_WEB_TITLE: "Web",
  DEV_WEB_SUBTITLE: "Manage your habits from any browser",
  DEV_WEB_VISIT: "Visit",
  DEV_APP_TITLE: "Mobile",
  DEV_APP_SUBTITLE: "Add and manage habits on-the-go",
  DEV_APP_APPLE: "iPhone & iPad",
  DEV_APP_ANDROID: "Android",
  DEV_WEBEXT_TITLE: "Browser Extensions",
  DEV_WEBEXT_SUBTITLE: "Set everyday as the default new tab",
  DEV_WEBEXT_CHROME: "Chrome",
  DEV_WEBEXT_FIREFOX: "Firefox",
  DEV_WEBEXT_SAFARI: "Safari",
  DEV_WEBEXT_EDGE: "Edge",
  DEV_WEBEXT_CHROME_BTN: "Add to Chrome",
  DEV_WEBEXT_FIREFOX_BTN: "Add to Firefox",
  DEV_WEBEXT_SAFARI_BTN: "Add to Safari",
  DEV_WEBEXT_EDGE_BTN: "Add to Edge",
  DEV_DESKTOP_TITLE: "macOS",
  DEV_DESKTOP_SUBTITLE: "Launch Everyday from your Dock",
  DEV_WATCH_TITLE: "watchOS",
  DEV_WATCH_SUBTITLE: "Track your habits from your wrist",
};
