import fetch from 'isomorphic-fetch';
import * as storage from 'utils/storage';
import config from 'config';

export default function request(params) {
  const user = storage.get('edc_user');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (user) {
    headers.Authorization = `Basic ${user.token}`;
  }
  return fetch(`${config.apiUrl}/${params.url}`, {
    method: params.method || 'GET',
    body: JSON.stringify(params.body),
    headers,
  })
   .then(res => {
     if (res.status >= 400) {
       return res.json().then(err => Promise.reject(err.errors));
     }
     const totalHeader = res.headers.get('X-Total');
     const total = totalHeader && parseInt(totalHeader, 10);
     if (total !== null) return res.json().then(data => ({ data, total }));
     return res.json();
   });
}
