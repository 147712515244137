import React from "react";
// import * as Components from 'components';
import { Helmet } from "react-helmet";
import messages from "utils/messages";

export default class Principles extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="principles-page">
        <Helmet>
          <title>{messages.H_PRINCIPLES}</title>
          <link
            rel="alternate"
            href="https://everyday.app/principles"
            hrefLang="en"
          />
        </Helmet>
        Principles!
      </div>
    );
  }
}
