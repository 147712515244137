import React from "react";
import * as Components from "components";
import * as Pages from "pages";
// import ua from 'universal-analytics';
// import config from 'config';
import { Helmet } from "react-helmet";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import messages from "utils/messages";

export default class App extends React.Component {
  // constructor() {
  //   super();
  //   this.visitor = ua(config.ga, { https: true });
  // }
  // componentWillMount() {
  //   this.visitor.pageview(this.props.location.pathname).send();
  // }
  // componentWillReceiveProps(newProps) {
  //   if (this.props.location.pathname !== newProps.location.pathname) {
  //     this.visitor.pageview(newProps.location.pathname).send();
  //   }
  // }
  render() {
    const { children } = this.props;

    return (
      <GoogleOAuthProvider clientId="924527963873-4hdkc4e7s76gn07o7l7o0ud29ng6em36.apps.googleusercontent.com">
        <Router>
          <div className="app">
            <Helmet
              defaultTitle={messages.H_MAIN}
              titleTemplate="everyday | %s"
            >
              <meta name="description" content={messages.H_DESCRIPTION} />
              <link rel="alternate" href="https://everyday.app" hrefLang="en" />
              <script type="application/ld+json">
                {`
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "everyday",
                "url": "https://everyday.app"
              }
            `}
              </script>
              <script type="application/ld+json">
                {`
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "url": "https://everyday.app",
                "logo": "https://everyday.app/app/images/everyday.png"
              }
            `}
              </script>
              <script type="application/ld+json">
                {`
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "everyday",
                "url": "https://everyday.app",
                "sameAs": [
                  "https://twitter.com/everydaycheck",
                  "https://www.facebook.com/everydaycheck",
                  "https://www.instagram.com/everydaycheck_/",
                  "https://pinterest.com/everydaycheck/",
                  "https://plus.google.com/u/2/116704389826680703466"
                ]
              }
            `}
              </script>
            </Helmet>
            <Components.Nav />

            <div className="page">
              <Routes>
                <Route path="/" element={<Pages.Landing />} />
                <Route path="/signup" element={<Pages.SignUp />} />
                <Route path="/login" element={<Pages.LogIn />} />
                <Route path="/forgot" element={<Pages.Forgot />} />
                <Route path="/reset/:token" element={<Pages.Reset />} />
                <Route path="/features" element={<Pages.Features />} />
                <Route path="/devices" element={<Pages.Devices />} />
                <Route path="/pricing" element={<Pages.Pricing />} />
                <Route path="/principles" element={<Pages.Principles />} />
                <Route path="/about-everyday" element={<Pages.AboutEDC />} />
                <Route path="/about-me" element={<Pages.AboutMe />} />
                <Route path="/featured" element={<Pages.Featured />} />
                <Route path="/faq" element={<Pages.FAQ />} />
                <Route path="/contact" element={<Pages.Contact />} />
                <Route path="/terms" element={<Pages.Terms />} />
                <Route path="/privacy" element={<Pages.Privacy />} />
                <Route path="*" element={<Pages.NotFound />} />
              </Routes>
            </div>
            <Components.Footer />
            <div className="clear"></div>
          </div>
        </Router>
      </GoogleOAuthProvider>
    );
  }
}
