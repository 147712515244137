import React from "react";
import * as Components from "components";
import messages from "utils/messages";
import { Helmet } from "react-helmet";

export default class Privacy extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="privacy-page">
        <Helmet>
          <title>{messages.H_PRIVACY}</title>
          <meta name="description" content={messages.H_DESC_PRIVACY} />
          <link
            rel="alternate"
            href="https://everyday.app/privacy"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.PRIVACY}</h1>
            <h2>{messages.P_SUBTITLE}</h2>
            <div className="text">
              <p>{messages.P_01}</p>
              <p>{messages.P_02}</p>
              <p>{messages.P_03}</p>
              <p>{messages.P_04}</p>
              <h3>{messages.P_ICAU}</h3>
              <p>{messages.P_05}</p>
              <h3>{messages.P_LOG}</h3>
              <p>{messages.P_06}</p>
              <h3>{messages.P_OTHER}</h3>
              <p>{messages.P_O_01}</p>
              <p>{messages.P_O_02}</p>
              <p>{messages.P_O_03}</p>
              <h3>{messages.P_COOKIES}</h3>
              <p>{messages.P_07}</p>
              <p>{messages.P_08}</p>
              <h3>{messages.P_SERVICE}</h3>
              <p>{messages.P_09}</p>
              <p>{messages.P_10}</p>
              <h3>{messages.P_SECURITY}</h3>
              <p>{messages.P_11}</p>
              <h3>{messages.P_LINKS}</h3>
              <p>{messages.P_12}</p>
              <p>{messages.P_13}</p>
              <h3>{messages.P_CHILDREN}</h3>
              <p>{messages.P_14}</p>
              <p>{messages.P_15}</p>
              <h3>{messages.P_CHANGES}</h3>
              <p>{messages.P_16}</p>
              <p>{messages.P_17}</p>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
