import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "containers";
import reportWebVitals from "./reportWebVitals";
import { CrossStorageHub } from "cross-storage";
import config from "config";

require("styles/base.scss");

CrossStorageHub.init([
  {
    // origin: config.externalApp,
    origin: new RegExp(config.externalApp),
    allow: ["get", "set", "del", "getKeys", "clear"],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
