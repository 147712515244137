import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";
import { Link } from "react-router-dom";
import config from "config";
import * as storage from "utils/storage";
import history from "utils/history";

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notfree: false,
    };
  }
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleClick() {
    const user = storage.get("edc_user");
    if (user) window.location.href = config.appUrl + "/subscribe";
    else window.location.href = config.appUrl + "/signup";
  }
  render() {
    return (
      <div className="pricing-page">
        <Helmet>
          <title>{messages.H_PRICING}</title>
          <meta name="description" content={messages.H_DESC_PRICING} />
          <link
            rel="alternate"
            href="https://everyday.app/pricing"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.PRI_TITLE}</h1>
            <h2>{messages.PRI_SUBTITLE}</h2>
            <ul>
              <li className="box">
                <div className="icon-wrapper">
                  <Components.Icon
                    name="Drop"
                    color="#249c03"
                    width="60"
                    height="60"
                  />
                </div>
                <h1>{messages.PRI_F_FREE}</h1>
                <div className="price-wrapper">
                  <div className="price-center">
                    <div className="price">{messages.PRI_F_PRICE}</div>
                    <div className="price-info">
                      {messages.PRI_F_SUB1} <br /> {messages.PRI_F_SUB2}
                    </div>
                  </div>
                </div>
                <ul className="features">
                  <li>{messages.PRI_F_FEAT1}</li>
                  <li>{messages.PRI_F_FEAT2}</li>
                  <li>{messages.PRI_F_FEAT3}</li>
                  <li>{messages.PRI_F_FEAT4}</li>
                  <li>{messages.PRI_F_FEAT5}</li>
                  <li>{messages.PRI_F_FEAT6}</li>
                  <li>{messages.PRI_F_FEAT7}</li>
                  <li>{messages.PRI_F_FEAT8}</li>
                </ul>
                <div className="buttons">
                  <Link to="/signup" className="button">
                    {messages.PRI_F_BTN}
                  </Link>
                </div>
              </li>
              <li className="box">
                <div className="icon-wrapper">
                  <Components.Icon
                    name="Star"
                    color="#249c03"
                    width="60"
                    height="60"
                  />
                </div>
                <h1>{messages.PRI_P_PREMIUM}</h1>
                <div className="price-wrapper">
                  <div className="price-center">
                    <div className="price">{messages.PRI_P_PRICE}</div>
                    <div className="price-info">
                      {messages.PRI_P_SUB1} <br /> {messages.PRI_P_SUB2}
                    </div>
                  </div>
                </div>
                <ul className="features">
                  <li>{messages.PRI_P_FEAT1}</li>
                  <li>{messages.PRI_P_FEAT2}</li>
                  <li>{messages.PRI_P_FEAT3}</li>
                  <li>{messages.PRI_P_FEAT4}</li>
                  <li>{messages.PRI_P_FEAT5}</li>
                  <li>{messages.PRI_P_FEAT6}</li>
                  <li>{messages.PRI_P_FEAT7}</li>
                  <li>{messages.PRI_P_FEAT8}</li>
                </ul>
                <div className="buttons">
                  <a onClick={this.handleClick.bind(this)} className="button">
                    {messages.PRI_P_BTN}
                  </a>
                </div>
                <div className="notes">
                  <div>
                    {messages.PRI_P_NOTE1}{" "}
                    <span className="greenprice">{messages.PRI_P_NOTE2}</span>{" "}
                    {messages.PRI_P_NOTE3}
                  </div>
                  <div>
                    {messages.PRI_P_NOTE4}{" "}
                    <span className="boldpremium">{messages.PRI_P_NOTE5}</span>{" "}
                    {messages.PRI_P_NOTE6}{" "}
                    <span className="greenprice">{messages.PRI_P_NOTE7}</span>{" "}
                    {messages.PRI_P_NOTE8}
                  </div>
                </div>
              </li>
              <li className="box">
                <div className="icon-wrapper">
                  <Components.Icon
                    name="Rocket"
                    color="#249c03"
                    width="60"
                    height="60"
                  />
                </div>
                <h1>{messages.PRI_L_LIFETIME}</h1>
                <div className="price-wrapper">
                  <div className="price-center">
                    <div className="price">{messages.PRI_L_PRICE}</div>
                    <div className="price-info">
                      {messages.PRI_L_SUB1} <br /> {messages.PRI_L_SUB2}
                    </div>
                  </div>
                </div>
                <ul className="features">
                  <li>{messages.PRI_L_FEAT1}</li>
                  <li>{messages.PRI_L_FEAT2}</li>
                  <li>{messages.PRI_L_FEAT3}</li>
                </ul>
                <div className="buttons">
                  <a onClick={this.handleClick.bind(this)} className="button">
                    {messages.PRI_L_BTN}
                  </a>
                </div>
                <div className="notes">
                  <div>{messages.PRI_L_NOTE}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
