import React from "react";
// import * as Components from 'components';
import messages from "utils/messages";
import { signup, signInWithGoogle } from "actions/auth";
import { GoogleLogin } from "@react-oauth/google";
import config from "config";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
// import { signup, makeSubscription } from 'actions/auth';
// import everydayCheckIMG from '/images/everydaycheck.jpg';

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      message: "",
      notfree: false,
    };
  }
  onSubmit(event) {
    event.preventDefault();
    signup({
      name: this.nameInput.value,
      email: this.emailInput.value,
      password: this.passInput.value,
      signup_device: "web",
    }).then(
      () => null,
      (errors) => this.setState({ errors })
    );
  }
  signInWithApple() {
    window.location.replace(
      `https://appleid.apple.com/auth/authorize?client_id=app.everyday.web&redirect_uri=${config.appleRedirectUri}&response_type=code&scope=email name&response_mode=form_post`
    );
  }
  resetMessage() {
    this.setState({ message: "" });
  }
  whyNotFree() {
    this.setState({ notfree: !this.state.notfree });
  }
  render() {
    // return null;
    return (
      <div className="signup">
        <h2>{messages.SIGNUP_TITLE}</h2>
        {this.state.message.length ? (
          <div className="success-message">{this.state.message}</div>
        ) : (
          <div className="signup-form-wrapper">
            <div className="price-message">
              <div className="trial">{messages.FREE}</div>
              <div>{messages.FOREVER}</div>
            </div>
            {this.state.errors.length ? (
              <ul className="errors">
                {this.state.errors.map((error, key) => (
                  <li key={key}> {error} </li>
                ))}
              </ul>
            ) : null}
            <form className="signup-form" onSubmit={this.onSubmit.bind(this)}>
              <div className="form-row">
                <input
                  ref={(input) => {
                    this.nameInput = input;
                  }}
                  className="name signup-input"
                  autoComplete="name"
                  type="name"
                  name="name"
                />
                <label className="text-label">{messages.S_NAME}</label>
              </div>
              <div className="form-row">
                <input
                  ref={(input) => {
                    this.emailInput = input;
                  }}
                  className="email signup-input"
                  autoComplete="username"
                  type="email"
                  name="email"
                />
                <label className="text-label">{messages.S_EMAIL}</label>
              </div>
              <div className="form-row">
                <input
                  ref={(input) => {
                    this.passInput = input;
                  }}
                  className="password signup-input"
                  autoComplete="new-password"
                  type="password"
                  name="password"
                />
                <label className="text-label">{messages.S_PASSWORD}</label>
              </div>
              <div className="terms">
                <a>
                  <p>{messages.CONDITIONS}</p>
                  <a
                    href="https://everyday.app/terms"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {messages.CONDITIONS_0}
                  </a>
                  {messages.CONDITIONS_1}
                  <a
                    href="https://everyday.app/privacy"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {messages.CONDITIONS_2}
                  </a>
                </a>
              </div>
              <button type="submit" className="signup-button">
                {messages.SIGNUP}
              </button>
            </form>
            {this.props.appleSignIn && (
              <div className="apple-signin-button">
                <div className="or">
                  <a>{messages.OR}</a>
                </div>
                <div className="apple-button" onClick={this.signInWithApple}>
                  <span className="apple-logo"></span>
                  <span className="apple-sign-in-text">Sign in with Apple</span>
                </div>
                <ErrorBoundary>
                  <GoogleLogin
                    // useOneTap
                    width="500px"
                    size="large"
                    text="Sign in with Google"
                    type="standard"
                    shape="rectangular"
                    logo_alignment="center"
                    onSuccess={(credentialResponse) => {
                      console.log(credentialResponse);
                      signInWithGoogle({
                        token: credentialResponse.credential,
                      });
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

// <a className="whynot" onClick={this.whyNotFree.bind(this)}>
//  {messages.WHYNOTFREE}
// </a>
// {this.state.notfree ? (
//   <div className="because">
//     <p>{messages.BECAUSE}</p>
//     <p>{messages.BECAUSE_2}</p>
//     <p>{messages.BECAUSE_3}</p>
//     <p>
//       {messages.BECAUSE_4}
//       <a
//         className="link"
//         href="http://web.archive.org/web/20170628224824/https://blog.pinboard.in/2011/12/don_t_be_a_free_user/" // eslint-disable-line
//         target="_blank" rel="noreferrer"
//       >
//         {messages.BECAUSE_5}
//       </a>
//       {messages.BECAUSE_6}
//       <a
//         className="link"
//         href="mailto:joan@everyday.app"
//         target="_blank" rel="noreferrer"
//       >
//         {messages.BECAUSE_7}
//       </a>
//     </p>
//   </div>
// ) : null}
