import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";

export default class AboutEDC extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="aboutedc-page">
        <Helmet>
          <title>{messages.H_ABOUTEDC}</title>
          <meta name="description" content={messages.H_DESC_ABOUTEDC} />
          <link
            rel="alternate"
            href="https://everyday.app/about-everyday"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.AEDC_ABOUT_EDC}</h1>
            <div className="text">
              <p>{messages.AEDC_01}</p>
              <p>{messages.AEDC_02}</p>
              <p>{messages.AEDC_03}</p>
              <p>{messages.AEDC_04}</p>
              <p>{messages.AEDC_05}</p>
              <p>{messages.AEDC_06}</p>
              <p className="quote topm">{messages.AEDC_07}</p>
              <p className="quote">{messages.AEDC_08}</p>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
