import React from "react";
import * as Components from "components";
import messages from "utils/messages";
import { Link } from "react-router-dom";
// import header from 'images/header.png';
import show from "images/show.gif";
import iphone from "images/iphone@2x.png";
import android from "images/android@2x.png";
import { isAndroid, isIOS } from "react-device-detect";
import config from "config";

export default class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      android: false,
    };
  }

  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  showAndroid() {
    this.setState({ android: !this.state.android });
  }
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const promo = urlParams.get("promo");
    const ref = urlParams.get("ref");
    const source = ref || document.referrer;
    const appleStoreLink =
      "https://itunes.apple.com/us/app/everyday-habit-tracker/id1394150432";
    const googlePlayLink =
      "https://play.google.com/store/apps/details?id=com.everydaycheckmobile";

    return (
      <div className="landing">
        <div className="home container">
          <div className="home-wrapper">
            <h2>
              <span>{messages.QUOTE0}</span> {messages.QUOTE1}
            </h2>
            <h2>{messages.QUOTE2}</h2>
            {isAndroid ? (
              <div>
                <div className="buttons">
                  <a className="devbutton" href={googlePlayLink}>
                    <p>{messages.DOIT}</p>
                    <p>
                      <span>{messages.DOIT_ANDROID}</span>
                      <i className="ion-social-android"></i>
                    </p>
                  </a>
                </div>
                <div className="show deviceshow">
                  <img
                    className="android"
                    alt="everyday - Android habit tracker to
                    help you form good habits"
                    src={android}
                  />
                </div>
              </div>
            ) : (
              <div>
                {isIOS ? (
                  <div>
                    <div className="buttons">
                      <a className="devbutton" href={appleStoreLink}>
                        <p>{messages.DOIT}</p>
                        <p>
                          <span>{messages.DOIT_IOS}</span>
                          <i className="ion-social-apple"></i>
                        </p>
                      </a>
                    </div>
                    <div className="show deviceshow">
                      <img
                        className="android"
                        alt="everyday - iPhone and iPad habit tracker to
                      help you form good habits"
                        src={iphone}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="buttons doit">
                      <Link
                        to={"/signup" + window.location.search}
                        className="button"
                      >
                        {messages.DOIT}
                      </Link>
                    </div>
                    <div className="show">
                      <dots>
                        <dot></dot>
                        <dot></dot>
                        <dot></dot>
                      </dots>
                      <img
                        alt="everyday - Simple and beautiful habit tracker to
                      help you form good habits"
                        src={show}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="description">
          <div className="container">
            <div className="description-wrapper">
              <h1>{messages.SLOGAN}</h1>
              <h2>{messages.DESCRIPTION}</h2>
              <div className="features">
                <div className="block">
                  <i className="ion-waterdrop"></i>
                  <h3>{messages.L_FEAT_T1}</h3>
                  <a>{messages.L_FEAT_D1}</a>
                </div>
                <div className="block">
                  <i className="ion-ios-infinite"></i>
                  <h3>{messages.L_FEAT_T2}</h3>
                  <a>{messages.L_FEAT_D2}</a>
                </div>
                <div className="block">
                  <i className="ion-eye"></i>
                  <h3>{messages.L_FEAT_T3}</h3>
                  <a>{messages.L_FEAT_D3}</a>
                </div>
                <div className="clear"></div>
              </div>
              <div className="buttons">
                <Link to="/features" className="button">
                  {messages.FEATURES}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="devices">
          <div className="container">
            <div className="devices-wrapper">
              <div className="left">
                <div className="left-wrapper">
                  <h1 className="everyday">{messages.DEV_EVERYDAY}</h1>
                  <h1 className="everywhere">{messages.DEV_EVERYWHERE}</h1>
                  <div className="buttons">
                    <a className="iphone" href={appleStoreLink}>
                      <span>{messages.DEV_IPHONE}</span>{" "}
                      <i className="ion-social-apple"></i>
                    </a>
                    <a className="android" href={googlePlayLink}>
                      <span>{messages.DEV_ANDROID}</span>{" "}
                      <i className="ion-social-android"></i>
                    </a>
                  </div>
                  <div className="text">
                    {messages.DEV_DEVICES}
                    <Link to="/devices" className="devices">
                      {messages.DEV_DEVICES_MORE}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="right">
                {isAndroid || isIOS ? (
                  <div className="webapp">
                    <dots>
                      <dot></dot>
                      <dot></dot>
                      <dot></dot>
                    </dots>
                    <img
                      alt="everyday - Simple and beautiful habit tracker to
                      help you form good habits"
                      src={show}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      className="iphone"
                      alt="everyday - iPhone habit tracker to
                      help you form good habits"
                      src={iphone}
                    />
                    {this.state.android ? (
                      <img
                        className="android"
                        alt="everyday - Android habit tracker to
                        help you form good habits"
                        src={android}
                      />
                    ) : null}
                    <a
                      className="change-device"
                      onClick={this.showAndroid.bind(this)}
                    >
                      <i className="ion-android-sync"></i>
                      {this.state.android ? (
                        <span>{messages.DEV_TO_IPHONE}</span>
                      ) : (
                        <span>{messages.DEV_TO_ANDROID}</span>
                      )}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="signup-landing">
          <div className="container">
            <div className="signup-wrapper">
              <Components.SignUp />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
