import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";
import visualize from "images/other/visualize.gif";
import reminders from "images/other/reminders.png";
import webext from "images/other/webext.png";
import iphone from "images/iphone@2x.png";
import android from "images/android@2x.png";
import customize from "images/other/customize.gif";
import stats from "images/other/stats.gif";
import skips from "images/other/skips.gif";
import emoji from "images/other/emoji.png";
import { Link } from "react-router-dom";

export default class Features extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="features-page">
        <Helmet>
          <title>{messages.H_FEATURES}</title>
          <meta name="description" content={messages.H_DESC_FEATURES} />
          <link
            rel="alternate"
            href="https://everyday.app/features"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.FEA_FEATURES}</h1>
            <h2>{messages.FEA_SUBTITLE}</h2>
            <div className="features">
              <div className="feature">
                <div className="block a withpadding">
                  <i className="ion-eye"></i>
                  <h3>{messages.FEA_VISUALIZE}</h3>
                  <a>{messages.FEA_VISUALIZE_TEXT}</a>
                  <div className="line"></div>
                </div>
                <div className="block b">
                  <img alt={messages.FEA_VISUALIZE} src={visualize} />
                </div>
                <div className="clear"></div>
              </div>
              <div className="feature">
                <div className="block">
                  <img
                    alt={messages.FEA_REMINDERS}
                    src={reminders}
                    className="withborder"
                  />
                </div>
                <div className="block withpadding">
                  <i className="ion-ios-bell-outline"></i>
                  <h3>{messages.FEA_REMINDERS}</h3>
                  <a>{messages.FEA_REMINDERS_TEXT}</a>
                  <div className="line"></div>
                </div>
                <div className="clear"></div>
              </div>
              <div className="feature">
                <div className="block withpadding">
                  <i className="ion-home"></i>
                  <h3>{messages.FEA_WEBEXT}</h3>
                  <a>{messages.FEA_WEBEXT_TEXT}</a>
                  <div className="line"></div>
                </div>
                <div className="block">
                  <img alt={messages.FEA_WEBEXT} src={webext} />
                </div>
                <div className="clear"></div>
              </div>
              <div className="feature">
                <div className="block devices">
                  <img
                    className="iphone"
                    alt="everyday - iPhone habit tracker to
                  help you form good habits"
                    src={iphone}
                  />
                  <img
                    className="android"
                    alt="everyday - Android habit tracker to
                    help you form good habits"
                    src={android}
                  />
                </div>
                <div className="block withpadding">
                  <i className="ion-iphone"></i>
                  <h3>{messages.FEA_DEVICES}</h3>
                  <a>{messages.FEA_DEVICES_TEXT}</a>
                  <p>
                    <a className="noblock">{messages.FEA_DEVICES_SEE}</a>
                    <Link to="/devices" className="link">
                      {messages.FEA_DEVICES_DEVICES}
                    </Link>
                  </p>
                  <div className="line"></div>
                </div>
                <div className="clear"></div>
              </div>
              <div className="feature">
                <div className="block b withpadding">
                  <i className="ion-paintbucket"></i>
                  <h3>{messages.FEA_CUSTOMIZE}</h3>
                  <a>{messages.FEA_CUSTOMIZE_TEXT}</a>
                  <div className="line"></div>
                </div>
                <div className="block a">
                  <img alt={messages.FEA_CUSTOMIZE} src={customize} />
                </div>
                <div className="clear"></div>
              </div>
              <div className="feature">
                <div className="block a">
                  <img alt={messages.FEA_STATS} src={stats} />
                </div>
                <div className="block b withpadding">
                  <i className="ion-stats-bars"></i>
                  <h3>{messages.FEA_STATS}</h3>
                  <a>{messages.FEA_STATS_TEXT}</a>
                  <div className="line"></div>
                </div>
                <div className="clear"></div>
              </div>
              <div className="feature">
                <div className="block c withpadding">
                  <i className="ion-arrow-graph-up-right"></i>
                  <h3>{messages.FEA_SKIPS}</h3>
                  <a>{messages.FEA_SKIPS_TEXT}</a>
                  <div className="line"></div>
                </div>
                <div className="block d">
                  <img alt={messages.FEA_SKIPS} src={skips} />
                </div>
                <div className="clear"></div>
              </div>
              <div className="feature">
                <div className="block a">
                  <img alt={messages.FEA_EMOJI} src={emoji} />
                </div>
                <div className="block b withpadding">
                  <i className="ion-earth"></i>
                  <h3>{messages.FEA_EMOJI}</h3>
                  <a>{messages.FEA_EMOJI_TEXT}</a>
                  <div className="line"></div>
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
