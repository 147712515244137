import React from "react";
import messages from "utils/messages";
import { forgot } from "actions/auth";
import { Link } from "react-router-dom";

export default class Forgot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
    };
  }
  componentWillMount() {}
  onSubmit(event) {
    event.preventDefault();
    forgot({
      email: this.emailInput.value,
    }).then(() => this.setState({ message: messages.EMAIL_SENT }));
  }
  render() {
    return (
      <div className="forgot">
        <h2>{messages.FORGOT_TITLE}</h2>
        <h3>{messages.FORGOT_TEXT}</h3>
        <div className="forgot-form-wrapper">
          {this.state.message.length ? (
            <ul className="message">
              <li> {this.state.message} </li>
            </ul>
          ) : null}
          <form className="forgot-form" onSubmit={this.onSubmit.bind(this)}>
            <div className="form-row">
              <input
                ref={(input) => {
                  this.emailInput = input;
                }}
                className="email email-input"
                type="email"
                name="email"
              ></input>
              <label className="text-label">{messages.S_EMAIL}</label>
            </div>
            <button type="submit" className="send-button">
              {messages.SEND_EMAIL}
            </button>
          </form>
          <Link to="/login" className="back-to-login">
            {messages.F_LOGIN}
          </Link>
        </div>
      </div>
    );
  }
}
