import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";
import { Link } from "react-router-dom";

export default class NotFound extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="notfound-page">
        <Helmet>
          <title>{messages.H_NOTFOUND}</title>
          <meta name="description" content={messages.H_DESC_NOTFOUND} />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.NF_TITLE}</h1>
            <a>{messages.NF_01} </a>
            <Link className="link" to="/">
              {messages.NF_02}
            </Link>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
