import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";

export default class AboutMe extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="aboutme-page">
        <Helmet>
          <title>{messages.H_ABOUTME}</title>
          <meta name="description" content={messages.H_DESC_ABOUTME} />
          <link
            rel="alternate"
            href="https://everyday.app/about-me"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.AME_TITLE}</h1>
            <h2>{messages.AME_SUBTITLE}</h2>
            <div className="text">
              <p>{messages.AME_01}</p>
              <p>
                {messages.AME_02}
                <a
                  href="https://listlogs.com/mezod/films"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_03}
                </a>
                <a
                  href="https://listlogs.com/mezod/bands"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_04}
                </a>
                {messages.AME_05}
                <a
                  href="https://listlogs.com/mezod/books"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_06}
                </a>
                {messages.AME_07}
                <a
                  href="http://joanboixados.com/Macrotrip"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_08}
                </a>
                {messages.AME_09}
              </p>
              <p>
                {messages.AME_10}
                <a
                  href="http://multikanban.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_11}
                </a>
                {messages.AME_12}
              </p>
              <p>{messages.AME_13}</p>
              <p>
                {messages.AME_14}
                <a
                  href="https://github.com/mezod/awesome-indie"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_15}
                </a>
                {messages.AME_16}
                {messages.AME_17}
                <a
                  href="https://medium.com/@mezood/making-money-from-your-own-code-has-never-been-easier-but-its-still-hard-2242ddaae6a1" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_18}
                </a>
              </p>
              <p>{messages.AME_19}</p>
              <p className="signature">{messages.AME_20}</p>
              <p>
                {messages.AME_21}
                <a
                  href="mailto:joan@everyday.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.AME_22}
                </a>
              </p>
              <p>{messages.AME_23}</p>
              <ul>
                <li>
                  <a
                    href="http://joanboixados.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.AME_24}
                  </a>
                </li>
                <li>
                  <a
                    href="http://blog.joanboixados.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.AME_25}
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/mezood"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.AME_26}
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/mezod"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.AME_27}
                  </a>
                </li>
                <li>
                  <a
                    href="https://listlogs.com/mezod"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.AME_28}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
