import React from "react";
import * as Components from "components";

export default class Forgot extends React.Component {
  componentWillMount() {}
  render() {
    return (
      <div className="forgot-page">
        <Components.Forgot />
      </div>
    );
  }
}
