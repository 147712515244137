const config = {};

for (const prop in process.env) {
  if (prop.includes("REACT_APP_")) {
    const key = prop.replace("REACT_APP_", "");
    config[key] = process.env[prop];
  }
}

export default config;
