import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";
import trendiestBetalist from "images/other/trendiest-betalist.png";
import hh from "images/other/hh.png";
import hn from "images/other/hn.png";
import ph from "images/other/ph.png";

export default class Featured extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="featured-page">
        <Helmet>
          <title>{messages.H_FEATURED}</title>
          <meta name="description" content={messages.H_DESC_FEATURED} />
          <link
            rel="alternate"
            href="https://everyday.app/featured"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.FEAT_TITLE}</h1>
            <h2>{messages.FEAT_SUBTITLE}</h2>
            <div className="text">
              <p>
                <span>{messages.FEAT_08_DATE}</span>
                <a
                  href="https://www.offscreenmag.com/dispatch" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FEAT_08}
                </a>
              </p>
              <p>
                <span>{messages.FEAT_07_DATE}</span>
                <a
                  href="https://wwwhatsnew.com/2017/07/03/everydaycheck-para-hacer-seguimiento-diario-de-los-propios-habitos/" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FEAT_07}
                </a>
              </p>
              <p>
                <span>{messages.FEAT_06_DATE}</span>
                <a
                  href="https://www.producthunt.com/posts/everydaycheck-2" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FEAT_06}
                </a>
              </p>
              <img alt={messages.FEAT_06_ALT} src={ph} />
              <p>
                <span>{messages.FEAT_05_DATE}</span>
                <a
                  href="https://news.ycombinator.com/item?id=14653078" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FEAT_05}
                </a>
              </p>
              <img alt={messages.FEAT_05_ALT} src={hn} />
              <p>
                <span>{messages.FEAT_04_DATE}</span>
                <a>{messages.FEAT_04}</a>
              </p>
              <img alt={messages.FEAT_04_ALT} src={hh} />
              <p>
                <span>{messages.FEAT_03_DATE}</span>
                <a
                  href="https://www.reddit.com/r/GetMotivated/comments/6jlpdy/tool_thought_i_owed_this_subreddit_a_lot_so_im/" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FEAT_03}
                </a>
              </p>
              <p>
                <span>{messages.FEAT_02_DATE}</span>
                <a
                  href="https://medium.com/@BetaList/startups-of-may-bfaa0904b5b6" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FEAT_02}
                </a>
              </p>
              <img alt={messages.FEAT_02_ALT} src={trendiestBetalist} />
              <p>
                <span>{messages.FEAT_01_DATE}</span>
                <a
                  href="https://www.reddit.com/r/getdisciplined/comments/60akoi/method_thought_i_owed_this_subreddit_a_lot_so_im/" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FEAT_01}
                </a>
              </p>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
