import request from "utils/request";
import * as storage from "utils/storage";
import { CrossStorageClient } from "cross-storage";
import config from "config";

export function signup(params) {
  const urlParams = new URLSearchParams(window.location.search);
  const promo = urlParams.get("promo");
  const ref = urlParams.get("ref");

  const source = ref || document.referrer;
  // return console.log(promo);
  return request({
    url: `signup?promo=${promo}`,
    method: "POST",
    body: { ...params, device_os: "web", promo, source },
  }).then((res) => {
    const user = Object.assign(res);
    user.token = btoa(
      unescape(encodeURIComponent(`${params.email}:${params.password}`))
    );

    const crossStorage = new CrossStorageClient(config.appUrl);

    crossStorage
      .onConnect()
      .then(() =>
        crossStorage
          .set("edc_user", JSON.stringify(user))
          .then((window.location = config.appUrl))
      );

    // guardem a localStorage per redirigir a app si logged in
    storage.set({
      key: "edc_user",
      value: user,
      persist: true,
    });

    return user;
  });
}

export function login(params) {
  return request({
    url: "login",
    method: "POST",
    body: { ...params, device_os: "web" },
  }).then(
    (res) => {
      const user = Object.assign(res);
      user.token = btoa(
        unescape(encodeURIComponent(`${params.email}:${params.password}`))
      );

      const crossStorage = new CrossStorageClient(config.appUrl);

      crossStorage
        .onConnect()
        .then(() =>
          crossStorage
            .set("edc_user", JSON.stringify(user))
            .then((window.location = config.appUrl))
        );

      // guardem a localStorage per redirigir a app si logged in
      storage.set({
        key: "edc_user",
        value: user,
        persist: true,
      });
    },
    (errors) => errors
  );
}

export async function signInWithGoogle(params) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const promo = urlParams.get("promo");
    const ref = urlParams.get("ref");
    const body = { ...params, os: "web", promo, ref };

    const res = await request({
      url: "signInWithGoogle",
      method: "POST",
      body,
    });
    const user = Object.assign(res);

    const crossStorage = new CrossStorageClient(config.appUrl);

    crossStorage
      .onConnect()
      .then(() =>
        crossStorage
          .set("edc_user", JSON.stringify(user))
          .then((window.location = config.appUrl))
      );

    storage.set({
      key: "edc_user",
      value: user,
      persist: true,
    });
  } catch (err) {
    if (err instanceof Error) {
      console.log("sign up with google failed");
    } else {
      throw err;
    }
  }
}

export function forgot(params) {
  return request({
    url: "forgot",
    method: "POST",
    body: params,
  });
}

export function reset(params) {
  return request({
    url: "reset",
    method: "POST",
    body: params,
  }).then(
    (res) => {
      const user = Object.assign(res);
      user.token = btoa(
        unescape(encodeURIComponent(`${user.email}:${params.password}`))
      );

      const crossStorage = new CrossStorageClient(config.appUrl);

      crossStorage
        .onConnect()
        .then(() =>
          crossStorage
            .set("edc_user", JSON.stringify(user))
            .then((window.location = config.appUrl))
        );

      // guardem a localStorage per redirigir a app si logged in
      storage.set({
        key: "edc_user",
        value: user,
        persist: true,
      });

      return;
    },
    (errors) => errors
  );
}

export function makeSubscription(params) {
  return request({
    url: "makeSubscription",
    method: "POST",
    body: params,
  });
}

export function restore() {
  const user = storage.get("edc_user");

  if (user) {
    // force log in if new fields in db (when updates)
    // MAKE SURE FIELD CAN NEVER BE 0
    if (user.mailing_list === undefined) return;

    // redirect to app
    window.location = config.externalAppUrl;
  }
}
