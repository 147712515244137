import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";
import iphone from "images/app_store/app_store_icons_ios.svg";
import android from "images/app_store/app_store_icons_google.svg";
import mac from "images/app_store/app_store_icons_mac.svg";

export default class Devices extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="devices-page">
        <Helmet>
          <title>{messages.H_DEVICES}</title>
          <meta name="description" content={messages.H_DESC_DEVICES} />
          <link
            rel="alternate"
            href="https://everyday.app/devices"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.DEV_TITLE}</h1>
            <ul>
              <li className="box">
                <h1>{messages.DEV_WEB_TITLE}</h1>
                <h2>{messages.DEV_WEB_SUBTITLE}</h2>
                <div className="device-image browser-image"></div>
                <a
                  href="https://app.everyday.app"
                  target="_blank"
                  rel="noreferrer"
                  className="button-devices"
                >
                  {messages.DEV_WEB_VISIT}
                </a>
              </li>
              <li className="box">
                <h1>{messages.DEV_APP_TITLE}</h1>
                <h2>{messages.DEV_APP_SUBTITLE}</h2>
                <div className="device-image app-image"></div>
                <ul>
                  <li className="extramargin">
                    <h3>{messages.DEV_APP_APPLE}</h3>
                    <a
                      href="https://itunes.apple.com/us/app/everyday-habit-tracker/id1394150432"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img role="presentation" src={iphone} />
                    </a>
                  </li>
                  <li>
                    <h3>{messages.DEV_APP_ANDROID}</h3>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.everydaycheckmobile"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img role="presentation" src={android} />
                    </a>
                  </li>
                </ul>
              </li>
              <li className="box">
                <h1>{messages.DEV_WEBEXT_TITLE}</h1>
                <h2>{messages.DEV_WEBEXT_SUBTITLE}</h2>
                <div className="device-image webext-image"></div>
                <ul>
                  <li>
                    <h3>{messages.DEV_WEBEXT_CHROME}</h3>
                    <a
                      href="https://chrome.google.com/webstore/detail/everydaycheck-do-it-every/kpcjjapfjdgjlmoehhliomplgjnbdcpc" // eslint-disable-line
                      target="_blank"
                      rel="noreferrer"
                      className="button-devices"
                    >
                      {messages.DEV_WEBEXT_CHROME_BTN}
                    </a>
                  </li>
                  <li>
                    <h3>{messages.DEV_WEBEXT_FIREFOX}</h3>
                    <a
                      href="https://addons.mozilla.org/en-US/firefox/addon/everydaycheck-do-it-every-day/?src=ss" // eslint-disable-line
                      target="_blank"
                      rel="noreferrer"
                      className="button-devices"
                    >
                      {messages.DEV_WEBEXT_FIREFOX_BTN}
                    </a>
                  </li>
                  <li>
                    <h3>{messages.DEV_WEBEXT_SAFARI}</h3>
                    <a
                      href="https://apps.apple.com/us/app/everyday-habit-tracker/id1394150432#?platform=mac" // eslint-disable-line
                      target="_blank"
                      rel="noreferrer"
                      className="button-devices"
                    >
                      {messages.DEV_WEBEXT_SAFARI_BTN}
                    </a>
                  </li>
                  <li>
                    <h3>{messages.DEV_WEBEXT_EDGE}</h3>
                    <a
                      href="https://microsoftedge.microsoft.com/addons/detail/everydayapp-do-it-ever/aohnmbicbnelnkgjimgnjekcgfabjmnn" // eslint-disable-line
                      target="_blank"
                      rel="noreferrer"
                      className="button-devices"
                    >
                      {messages.DEV_WEBEXT_EDGE_BTN}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className="box solo">
                <h1>{messages.DEV_DESKTOP_TITLE}</h1>
                <h2>{messages.DEV_DESKTOP_SUBTITLE}</h2>
                <div className="device-image desktop-image"></div>
                <ul>
                  <li>
                    <a
                      href="https://apps.apple.com/us/app/everyday-habit-tracker/id1394150432#?platform=mac"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img role="presentation" src={mac} />
                    </a>
                  </li>
                </ul>
              </li>
              <li className="box solo">
                <h1>{messages.DEV_WATCH_TITLE}</h1>
                <h2>{messages.DEV_WATCH_SUBTITLE}</h2>
                <div className="device-image watch-image"></div>
                <ul>
                  <li>
                    <a
                      href="https://itunes.apple.com/us/app/everyday-habit-tracker/id1394150432"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img role="presentation" src={iphone} />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
