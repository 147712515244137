import React from "react";
// import * as Components from 'components';
import messages from "utils/messages";
import { login, signInWithGoogle } from "actions/auth";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import config from "config";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

export default class LogIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
  }
  onSubmit(event) {
    event.preventDefault();
    login({
      email: this.emailInput.value,
      password: this.passInput.value,
    }).then((errors) => this.setState({ errors }));
  }
  signInWithApple() {
    window.location.replace(
      `https://appleid.apple.com/auth/authorize?client_id=app.everyday.web&redirect_uri=${config.appleRedirectUri}&response_type=code&scope=email name&response_mode=form_post`
    );
  }
  render() {
    return (
      <div className="login">
        <h2>{messages.LOGIN_TITLE}</h2>
        <div className="login-form-wrapper">
          {this.state.errors.length ? (
            <ul className="errors">
              {this.state.errors.map((error, key) => (
                <li key={key}> {error} </li>
              ))}
            </ul>
          ) : null}
          <form className="login-form" onSubmit={this.onSubmit.bind(this)}>
            <div className="form-row">
              <input
                ref={(input) => {
                  this.emailInput = input;
                }}
                className="email login-input"
                autoComplete="username"
                type="email"
                name="email"
              />
              <label className="text-label">{messages.S_EMAIL}</label>
            </div>
            <div className="form-row">
              <input
                ref={(input) => {
                  this.passInput = input;
                }}
                className="password login-input"
                autoComplete="current-password"
                type="password"
                name="password"
              />
              <label className="text-label">{messages.S_PASSWORD}</label>
            </div>
            <button type="submit" className="login-button">
              {messages.LOGIN}
            </button>
          </form>
          <Link to="/forgot" className="forgot">
            {messages.L_FORGOT}
          </Link>

          <div className="apple-signin-button">
            <div className="or">
              <a>{messages.OR}</a>
            </div>
            <div className="apple-button" onClick={this.signInWithApple}>
              <span className="apple-logo"></span>
              <span className="apple-sign-in-text">Sign in with Apple</span>
            </div>
            <ErrorBoundary>
              <GoogleLogin
                // useOneTap
                width="500px"
                size="large"
                text="Sign in with Google"
                type="standard"
                shape="rectangular"
                logo_alignment="center"
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                  signInWithGoogle({
                    token: credentialResponse.credential,
                  });
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
}
