import React from "react";
import messages from "utils/messages";
import { NavLink as Link } from "react-router-dom";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

export default class Footer extends React.Component {
  componentWillMount() {}
  render() {
    const tooltip = <Tooltip id="tooltip"> {messages.WAIT} </Tooltip>;
    const heap = { width: "108px", height: "41px" };
    /* eslint-disable */
    return (
      <div className="footer">
        <div className="container">
          <div className="footer-wrapper">
            <div className="made">
              <p>
                {messages.MADE_USING}
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <span>
                    <Link to="/">{messages.EVERY_DAY} </Link>
                  </span>
                </OverlayTrigger>
              </p>
              <p>
                {messages.IN}
                <span>
                  <svg
                    id="shape-sagrada-familia"
                    viewBox="0 0 345.666 345.666"
                    width="20px"
                    height="20px"
                  >
                    <path
                      fill="#249c03"
                      d="M270.09 72.28c5.662-2.892 9.344-8.713 9.344-15.315 0-9.515-7.746-17.255-17.264-17.255-9.512 0-17.246 7.74-17.246 17.255a17.153 17.153 0 0 0 9.325 15.315l-10.712 136.135-13.56-172.359c5.669-2.894 9.35-8.713 9.35-15.321 0-9.509-7.746-17.249-17.264-17.249-9.5 0-17.246 7.734-17.246 17.249 0 6.608 3.688 12.427 9.35 15.315L194.43 286.886l-15.877-141.852a17.125 17.125 0 0 0 10.046-15.648c0-9.41-7.557-17.081-16.934-17.255h-.646c-9.364.18-16.934 7.854-16.934 17.255a17.138 17.138 0 0 0 10.046 15.648l-14.099 126.089-18.785-238.628a17.141 17.141 0 0 0 9.229-15.24C140.477 7.74 132.736 0 123.225 0c-9.506 0-17.249 7.74-17.249 17.255a17.203 17.203 0 0 0 9.992 15.631L102.15 208.402 91.447 72.274a17.164 17.164 0 0 0 9.325-15.315c0-9.515-7.74-17.255-17.246-17.255-9.512 0-17.267 7.74-17.267 17.255 0 6.608 3.684 12.424 9.329 15.315L54.65 338.688l-.577 6.978h237.52L270.09 72.28zM85.217 64.272l-1.697.391-1.696-.391a7.466 7.466 0 0 1-5.831-7.308c0-4.152 3.381-7.533 7.539-7.533 4.149 0 7.521 3.381 7.521 7.533a7.474 7.474 0 0 1-5.836 7.308zm39.758-39.733l-1.531.369-1.499-.258c-3.615-.639-6.248-3.738-6.248-7.395 0-4.152 3.369-7.533 7.521-7.533 4.149 0 7.53 3.381 7.53 7.533.001 3.48-2.372 6.467-5.773 7.284zm46.396 113.172c-4.588 0-8.314-3.72-8.314-8.313s3.72-8.308 8.314-8.308c4.59 0 8.305 3.72 8.305 8.308s-3.72 8.313-8.305 8.313zm52.38-109.665l-1.688.387-1.669-.387c-3.447-.787-5.85-3.801-5.85-7.311 0-4.152 3.387-7.521 7.519-7.521 4.167 0 7.548 3.369 7.548 7.521a7.492 7.492 0 0 1-5.86 7.311zm40.118 36.226l-1.693.391-1.682-.391a7.456 7.456 0 0 1-5.831-7.308c0-4.152 3.381-7.533 7.519-7.533 4.161 0 7.548 3.381 7.548 7.533a7.513 7.513 0 0 1-5.861 7.308z"
                    ></path>
                  </svg>
                  {messages.BARCELONA}
                </span>
                {messages.BY}
                <a
                  href="https://twitter.com/mezood"
                  target="_blank"
                  rel="noreferrer"
                >
                  Joan Boixadós
                </a>
              </p>
            </div>
            <div className="columns">
              <ul>
                <li>
                  <span>{messages.F_PRODUCT}</span>
                </li>
                <li>
                  <Link to="/features">{messages.F_FEATURES}</Link>
                </li>
                <li>
                  <Link to="/devices">{messages.F_DEVICES}</Link>
                </li>
                <li>
                  <Link to="/pricing">{messages.F_PRICING}</Link>
                </li>
                <li>
                  <Link to={"/signup" + window.location.search}>
                    {messages.F_SIGNUP}
                  </Link>
                </li>
                <li>
                  <Link to="/login">{messages.F_LOGIN2}</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <span>{messages.F_COMPANY}</span>
                </li>
                <li>
                  <Link to="/about-everyday">{messages.F_ABOUT_EDC}</Link>
                </li>
                <li>
                  <Link to="/about-me">{messages.F_ABOUT_ME}</Link>
                </li>
                <li>
                  <a
                    href="https://blog.everyday.app"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.F_BLOG}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.dropbox.com/sh/tl33il6azg0fl2d/AAC9RInRKVCBi5U_tu9_u0fFa?dl=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.F_PRESSKIT}
                  </a>
                </li>
                {/* <li><Link to="/featured">{messages.F_FEATURED}</Link></li> */}
              </ul>
              <div className="clear-responsive"></div>
              <ul>
                <li>
                  <span>{messages.F_SUPPORT}</span>
                </li>
                <li>
                  <Link to="/faq">{messages.F_FAQ}</Link>
                </li>
                <li>
                  <Link to="/contact">{messages.F_CONTACT}</Link>
                </li>
                <li>
                  <Link to="/terms">{messages.F_TERMS}</Link>
                </li>
                <li>
                  <Link to="/privacy">{messages.F_PRIVACY}</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <span>{messages.F_FOLLOW}</span>
                </li>
                <li>
                  <a
                    href="http://twitter.com/everydaycheck"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.F_TWITTER}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/everydaycheck"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.F_FACEBOOK}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/everyday.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.F_INSTAGRAM}
                  </a>
                </li>
                <li>
                  <a
                    href="https://pinterest.com/everydaycheck/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.F_PINTEREST}
                  </a>
                </li>
              </ul>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    );
  }
}
