import React from "react";
import * as Components from "components";

export default class Reset extends React.Component {
  componentWillMount() {}
  render() {
    return (
      <div className="reset-page">
        <Components.Reset params={this.props.params} />
      </div>
    );
  }
}
