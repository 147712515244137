import React, { useState } from "react";
import messages from "utils/messages";
import { reset } from "actions/auth";
import { useParams } from "react-router";

export default function Reset() {
  const [errors, setErrors] = useState([]);
  const [passInput, setPassInput] = useState(null);
  const [confirmpassInput, setConfirmPass] = useState(null);
  const { token } = useParams();

  function onSubmit(event) {
    event.preventDefault();

    if (passInput !== confirmpassInput) {
      setErrors([messages.E_PW_MATCH]);
      return;
    }

    reset({
      token,
      password: passInput,
    }).then((errors) => errors && setErrors(errors));
  }
  return (
    <div className="reset">
      <h2>{messages.RESET_TITLE}</h2>
      <div className="reset-form-wrapper">
        {errors.length ? (
          <ul className="errors">
            {errors.map((error, key) => (
              <li key={key}> {error} </li>
            ))}
          </ul>
        ) : null}
        <form className="reset-form" onSubmit={onSubmit}>
          <div className="form-row">
            <input
              onChange={(event) => {
                setPassInput(event.target.value);
              }}
              className="password password-input"
              autoComplete="new-password"
              type="password"
              name="password"
            />
            <label className="text-label">{messages.R_PASSWORD}</label>
          </div>
          <div className="form-row">
            <input
              onChange={(event) => {
                setConfirmPass(event.target.value);
              }}
              className="password password-input"
              type="password"
              autoComplete="new-password"
              name="password"
            />
            <label className="text-label">{messages.R_CONFIRM_PASSWORD}</label>
          </div>
          <button type="submit" className="send-button">
            {messages.R_RESET_PASSWORD}
          </button>
        </form>
      </div>
    </div>
  );
}
