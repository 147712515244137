import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";

export default class Contact extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="contact-page">
        <Helmet>
          <title>{messages.H_CONTACT}</title>
          <meta name="description" content={messages.H_DESC_CONTACT} />
          <link
            rel="alternate"
            href="https://everyday.app/contact"
            hrefLang="en"
          />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.CONTACT}</h1>
            <h2>{messages.C_SUBTITLE}</h2>
            <div className="text">
              <p>
                {messages.C_01}
                <a
                  href="mailto:joan@everyday.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  joan@everyday.app
                </a>
                {messages.C_02}
                <a
                  href="http://twitter.com/everydaycheck"
                  target="_blank"
                  rel="noreferrer"
                >
                  everyday.
                </a>
                {messages.C_03}
              </p>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
