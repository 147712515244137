import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";

export default class FAQ extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="faq-page">
        <Helmet>
          <title>{messages.H_FAQ}</title>
          <meta name="description" content={messages.H_DESC_FAQ} />
          <link rel="alternate" href="https://everyday.app/faq" hrefLang="en" />
        </Helmet>
        <div className="container">
          <div className="wrapper">
            <h1>{messages.FAQ}</h1>
            <h2>{messages.FAQ_SUBTITLE}</h2>
            <div className="toc">
              <h3>{messages.FAQ_TOC}</h3>
              <a href="#about">{messages.FAQ_EDC}</a>
              <a href="#features">{messages.FAQ_FEATURES}</a>
              <a href="#support">{messages.FAQ_SUPPORT}</a>
              <a href="#privacy">{messages.FAQ_PRIVACY}</a>
            </div>
            <div className="text">
              <h3 id="about">{messages.FAQ_EDC}</h3>
              <a className="question" id="links">
                {messages.FAQ_Q1}
              </a>
              <p>{messages.FAQ_A1}</p>
              <a className="question">{messages.FAQ_Q2}</a>
              <p>{messages.FAQ_A2}</p>
              <p>{messages.FAQ_A2_2}</p>
              <p>{messages.FAQ_A2_3}</p>
              <p>{messages.FAQ_A2_4}</p>
              <p>{messages.FAQ_A2_5}</p>
              <a className="question">{messages.FAQ_Q3}</a>
              <p>{messages.FAQ_A3}</p>
              <p>{messages.FAQ_A3_2}</p>
              <p>{messages.FAQ_A3_3}</p>
              <p>{messages.FAQ_A3_4}</p>
              <p>{messages.FAQ_A3_5}</p>
              <p>{messages.FAQ_A3_6}</p>
              <a className="question">{messages.FAQ_Q4}</a>
              <p>{messages.FAQ_A4}</p>
              <p>{messages.FAQ_A4_1}</p>
              <ul>
                <li>{messages.FAQ_A4_2}</li>
                <li>{messages.FAQ_A4_3}</li>
                <li>{messages.FAQ_A4_4}</li>
                <li>{messages.FAQ_A4_5}</li>
                <li>{messages.FAQ_A4_6}</li>
                <li>{messages.FAQ_A4_7}</li>
                <li>{messages.FAQ_A4_8}</li>
                <li>{messages.FAQ_A4_9}</li>
                <li>{messages.FAQ_A4_10}</li>
                <li>{messages.FAQ_A4_11}</li>
                <li>{messages.FAQ_A4_12}</li>
                <li>{messages.FAQ_A4_13}</li>
                <li>{messages.FAQ_A4_14}</li>
              </ul>
              <a className="question" id="stack">
                {messages.FAQ_Q5}
              </a>
              <p>{messages.FAQ_A5}</p>
              <p>{messages.FAQ_A5_2}</p>
              <p>{messages.FAQ_A5_3}</p>
            </div>
            <div className="text">
              <h3 id="features">{messages.FAQ_FEATURES}</h3>
              <a className="question">{messages.FAQ_Q6}</a>
              <p>{messages.FAQ_A6}</p>
              <p>{messages.FAQ_A6_2}</p>
              <a className="question">{messages.FAQ_Q7}</a>
              <p>{messages.FAQ_A7}</p>
              <a className="question">{messages.FAQ_Q8}</a>
              <p>{messages.FAQ_A8}</p>
              <a className="question">{messages.FAQ_Q9}</a>
              <p>{messages.FAQ_A9}</p>
              <a className="question">{messages.FAQ_Q10}</a>
              <p>{messages.FAQ_A10}</p>
              <a className="question">{messages.FAQ_Q11}</a>
              <p>{messages.FAQ_A11}</p>
              <a className="question">{messages.FAQ_Q12}</a>
              <p>{messages.FAQ_A12}</p>
              <a className="question">{messages.FAQ_Q13}</a>
              <p>
                {messages.FAQ_A13}{" "}
                <a className="link" href="mailto:joan@everyday.app">
                  {messages.FAQ_A13_2}
                </a>
              </p>
              <a className="question">{messages.FAQ_Q14}</a>
              <p>{messages.FAQ_A14}</p>
              <a className="question">{messages.FAQ_Q15}</a>
              <p>
                {messages.FAQ_A15}{" "}
                <a className="link" href="mailto:joan@everyday.app">
                  {messages.FAQ_A13_2}
                </a>
              </p>
              <a className="question">{messages.FAQ_Q16}</a>
              <p>{messages.FAQ_A16}</p>
            </div>
            <div className="text">
              <h3 id="support">{messages.FAQ_SUPPORT}</h3>
              <a className="question">{messages.FAQ_Q17}</a>
              <p>
                {messages.FAQ_A17}{" "}
                <a
                  className="link"
                  href="https://everyday.app/forgot"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FAQ_A17_2}.
                </a>
              </p>
              <a className="question">{messages.FAQ_Q18}</a>
              <p>{messages.FAQ_A18}</p>
              <p>{messages.FAQ_A18_2}</p>
              <p>{messages.FAQ_A18_3}</p>
              <a className="question">{messages.FAQ_Q19}</a>
              <p>{messages.FAQ_A19}</p>
              <a id="chrome-webextension-install" className="question">
                {messages.FAQ_SWE_Q1}
              </a>
              <ol>
                <li>
                  {messages.FAQ_SWE_A11}
                  <a
                    className="link"
                    href="https://chrome.google.com/webstore/detail/everydaycheck-do-it-every/kpcjjapfjdgjlmoehhliomplgjnbdcpc" // eslint-disable-line
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.FAQ_SWE_A111}
                  </a>
                </li>
                <li>{messages.FAQ_SWE_A12}</li>
                <li>{messages.FAQ_SWE_A13}</li>
                <li>{messages.FAQ_SWE_A14}</li>
              </ol>
              <a id="chrome-webextension-uninstall" className="question">
                {messages.FAQ_SWE_Q2}
              </a>
              <ol>
                <li>{messages.FAQ_SWE_A2}</li>
                <li>{messages.FAQ_SWE_A21}</li>
              </ol>
              <a id="firefox-webextension-install" className="question">
                {messages.FAQ_SWE_Q3}
              </a>
              <ol>
                <li>
                  {messages.FAQ_SWE_A31}
                  <a
                    className="link"
                    href="https://addons.mozilla.org/en-US/firefox/addon/everydaycheck-do-it-every-day/?src=ss" // eslint-disable-line
                    target="_blank"
                    rel="noreferrer"
                  >
                    {messages.FAQ_SWE_A311}
                  </a>
                </li>
                <li>{messages.FAQ_SWE_A32}</li>
                <li>{messages.FAQ_SWE_A33}</li>
                <li>{messages.FAQ_SWE_A34}</li>
              </ol>
              <a id="firefox-webextension-uninstall" className="question">
                {messages.FAQ_SWE_Q4}
              </a>
              <ol>
                <li>{messages.FAQ_SWE_A4}</li>
                <li>{messages.FAQ_SWE_A41}</li>
              </ol>
              <a id="homepage" className="question">
                {messages.FAQ_SQ1}
              </a>
              <p>{messages.FAQ_SA1_1}</p>
              <ol>
                <li>{messages.FAQ_SA1_11}</li>
                <li>{messages.FAQ_SA1_12}</li>
                <li>{messages.FAQ_SA1_13}</li>
              </ol>
              <p>{messages.FAQ_SA1_2}</p>
              <ol>
                <li>{messages.FAQ_SA1_21}</li>
                <li>{messages.FAQ_SA1_22}</li>
                <li>{messages.FAQ_SA1_23}</li>
              </ol>
              <p>{messages.FAQ_SA1_3}</p>
              <ol>
                <li>{messages.FAQ_SA1_31}</li>
                <li>{messages.FAQ_SA1_32}</li>
                <li>{messages.FAQ_SA1_33}</li>
              </ol>
              <p>{messages.FAQ_SA1_4}</p>
              <ol>
                <li>{messages.FAQ_SA1_41}</li>
                <li>{messages.FAQ_SA1_42}</li>
                <li>{messages.FAQ_SA1_43}</li>
                <li>{messages.FAQ_SA1_44}</li>
                <li>{messages.FAQ_SA1_45}</li>
                <li>{messages.FAQ_SA1_46}</li>
              </ol>
              <p>{messages.FAQ_SA1_5}</p>
              <ol>
                <li>{messages.FAQ_SA1_51}</li>
                <li>{messages.FAQ_SA1_52}</li>
                <li>{messages.FAQ_SA1_53}</li>
                <li>{messages.FAQ_SA1_54}</li>
                <li>{messages.FAQ_SA1_55}</li>
              </ol>
              <a id="bookmark" className="question">
                {messages.FAQ_SQ2}
              </a>
              <p>
                {messages.FAQ_SA2}
                <a
                  className="link"
                  href="http://www.wikihow.com/Bookmark-a-Website"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FAQ_SA2_2}
                </a>
              </p>
              <a id="pinnedtab" className="question">
                {messages.FAQ_SQ3}
              </a>
              <p>{messages.FAQ_SA3}</p>
              <p>{messages.FAQ_SA3_2}</p>
              <a id="deskshortcut" className="question">
                {messages.FAQ_SQ4}
              </a>
              <p>{messages.FAQ_SA4}</p>
              <p>{messages.FAQ_SA4_1}</p>
              <p>{messages.FAQ_SA4_2}</p>
              <p>{messages.FAQ_SA4_3}</p>
              <a id="mobshortcut" className="question">
                {messages.FAQ_SQ5}
              </a>
              <p>{messages.FAQ_SA5}</p>
              <p>{messages.FAQ_SA5_1}</p>
              <ol>
                <li>{messages.FAQ_SA5_11}</li>
                <li>{messages.FAQ_SA5_12}</li>
              </ol>
              <p>{messages.FAQ_SA5_2}</p>
              <ol>
                <li>{messages.FAQ_SA5_21}</li>
                <li>{messages.FAQ_SA5_22}</li>
                <li>{messages.FAQ_SA5_23}</li>
                <li>{messages.FAQ_SA5_24}</li>
              </ol>
            </div>
            <div className="text">
              <h3 id="privacy">{messages.FAQ_PRIVACY}</h3>
              <a className="question">{messages.FAQ_Q20}</a>
              <p>{messages.FAQ_A20}</p>
              <a className="question">{messages.FAQ_Q201}</a>
              <p>{messages.FAQ_A201}</p>
              <a className="question">{messages.FAQ_Q21}</a>
              <p>{messages.FAQ_A21}</p>
              <a className="question">{messages.FAQ_Q22}</a>
              <p>
                {messages.FAQ_A22}
                <a
                  className="link"
                  href="https://stripe.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FAQ_A22_2}
                </a>
                {messages.FAQ_A22_3}
              </p>
              <a className="question">{messages.FAQ_Q23}</a>
              <p>{messages.FAQ_A23}</p>
              <p>{messages.FAQ_A23_2}</p>
              <a className="question" id="whynotfree">
                {messages.FAQ_Q24}
              </a>
              <p>{messages.FAQ_A24}</p>
              <p>{messages.FAQ_A24_2}</p>
              <p>{messages.FAQ_A24_3}</p>
              <p>
                {messages.FAQ_A24_4}
                <a
                  className="link"
                  href="http://web.archive.org/web/20170628224824/https://blog.pinboard.in/2011/12/don_t_be_a_free_user/" // eslint-disable-line
                  target="_blank"
                  rel="noreferrer"
                >
                  {messages.FAQ_A24_5}
                </a>
                {messages.FAQ_A24_6}
                <a className="link" href="mailto:joan@everyday.app">
                  {messages.FAQ_A24_7}
                </a>
              </p>
              <a className="question">{messages.FAQ_Q25}</a>
              <p>{messages.FAQ_A25}</p>
            </div>
          </div>
        </div>
        <Components.SignUp />
      </div>
    );
  }
}
