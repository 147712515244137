import React from "react";
import * as Components from "components";
import { Helmet } from "react-helmet";
import messages from "utils/messages";

export default class LogIn extends React.Component {
  componentWillMount() {}
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="login-page">
        <Helmet>
          <title>{messages.H_LOGIN}</title>
          <meta name="description" content={messages.H_DESC_LOGIN} />
          <link
            rel="alternate"
            href="https://everyday.app/login"
            hrefLang="en"
          />
        </Helmet>
        <Components.LogIn />
      </div>
    );
  }
}
